import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Header from '../components/Admin/Header/Header.js';
import routes from "../routesAdmin.js";

export default function Dashboard(props) {
    const { rest } = props;
    React.useEffect(() => {
        // if (window.location.pathname === "/user/login") {
        //     document.body.classList.add('login-container');
        // } else {
        //     document.body.classList.remove('login-container');
        // }
        return () => {
        }
    }, [])

    const isAuthorized = localStorage.getItem("isAdminAuthorized");

    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if ((prop.layout === "/admin" || prop.layout === "/") && isAuthorized && prop.isAccessRequired) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } 
            else if ((prop.layout === "/admin" || prop.layout === "/") && !prop.isAccessRequired) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } 
            else {
                return null;
            }
        });
    };

    return (
        window.location.pathname !== "/admin/login" ?
            <div className="main-container">
                <Header props={rest} />
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/admin/login" />
                </Switch>
            </div>
            :
            <div>
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/admin/login" />
                </Switch>
            </div>
    );
}
