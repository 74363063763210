import React, { Component } from 'react';
import logo from '../../assets/img/AccountingLogo.png';
import profiledummyimg from '../../assets/img/img1.jpeg';
import { Accordion, Dropdown, Modal } from 'react-bootstrap';
import api from "../../utils/apiClient";
import moment from 'moment';
import CommonConfig from '../../utils/constant';
import Swal from 'sweetalert2'
import '../../assets/css/stylesheet.scss';
import Dashboard from '../Dashboard/Dashboard.js'
import excelToJson from 'convert-excel-to-json';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import Select from 'react-select';
import DatePicker from 'react-multi-date-picker';


const initialState = {
    userAccessModel: false,
    menuList: [],
    userTypeList: [],
    showCreditModal: false,
    CreditEntry: [],
    DebitEntry: [],
    subscription_plan: 'Yearly',
    password: CommonConfig.randomString(32),
    username: CommonConfig.randomString(6),
    end_date: '',
    start_date: '',
    description: '',
    prefix: '',
    url: CommonConfig.randomString(6),
    contact_no: '',
    isPasswordShow: false,
    menuList: [],
    addUserModel: false,
    type: '',
    ledgerAccess: [],
    creditAccess: [],
    debitAccess: [],
    productAccess: [],
    taxAccess: [],
    userAccess: [],
    importAccess: []
}

export default class UserAccess extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        if (localStorage.getItem('IsUserLocked')) {
            this.props.history.push('/user/lockscreen')
        }
        //  this.getMenuMaster();
        var today = this.state.start_date ? this.state.start_date : new Date();
        today.setDate(today.getDate() + 365);
        this.setState({ start_date: this.state.start_date ? this.state.start_date : new Date(), end_date: new Date(today) });
        this.getUserList();
        this.getUserMenu();
    }

    getMenuMaster = async () => {
        try {
            await api
                .post('useraccess/getMenuMaster')
                .then(async (res) => {
                    let response = await res;
                    if (response.success) {

                        this.setState({
                            menuList: response.data,
                        });
                        console.log(typeof (this.state.menuList
                        ))
                    } else {
                        CommonConfig.showMessage(response.data[0].vMessage, response.data[0].vMessageType);
                    }
                })
                .catch((err) => {
                });
        } catch (err) {
        }
    };
    getUserAccessByID = async (ID) => {
        let data = {
            ID: ID
        }
        try {
            await api
                .post('useraccess/getUserAccessByID', data)
                .then(async (res) => {
                    let response = await res;
                    if (response.success) {

                        this.setState({
                            menuList: response.data,
                        });
                        console.log(typeof (this.state.menuList
                        ))
                    } else {
                        CommonConfig.showMessage(response.data[0].vMessage, response.data[0].vMessageType);
                    }
                })
                .catch((err) => {
                });
        } catch (err) {
        }
    };
    handleChangeMenu = (e, idx, type) => {
        if (type === "AddAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["AddAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "EditAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["EditAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "ViewAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["ViewAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "DeleteAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["DeleteAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        }
    };

    AddUserAccess = (e) => {
        e.preventDefault();
        try {
            let data = {
                SecurityUserID: CommonConfig.loginData().contact_no,
                AccessList: this.state.menuList,
                UserID: this.state.contact_no
            }
            api.post("useraccess/addUpdateUserAccess", data).then((res) => {
                if (res.success) {
                    this.handleClose("userAccessModel");
                    CommonConfig.showMessage(res.data[0].Message, res.data[0].MessageType);
                }
            }).catch(err => {

            })
        } catch (err) {

        }
    }

    getUserList = async () => {
        let data = {
            createdby: CommonConfig.loginData().contact_no,
        }
        try {
            await api.post("useraccess/getUserList", data)
                .then(async (res) => {
                    let response = await res;
                    if (response.success) {
                        this.setState({ userTypeList: response.data })
                    } else {
                        CommonConfig.showMessage(response.data[0].vMessage, response.data[0].vMessageType);
                    }
                })
                .catch((err) => {

                })
        } catch (err) {

        }
    }

    handleChange = (e, type) => {
        if (type == "subscription_plan") {
            if (e.target.value == "Monthly") {
                var today = this.state.start_date ? this.state.start_date : new Date();
                today.setDate(today.getDate() + 30);
                // console.log(today.getFullYear() + "-" + today.getMonth() + "-" + (today.getDate() + 30))
                this.setState({ start_date: this.state.start_date ? this.state.start_date : new Date(), end_date: new Date(today) })
            } else if (e.target.value == "Yearly") {
                var today = this.state.start_date ? this.state.start_date : new Date();
                today.setDate(today.getDate() + 365);
                this.setState({ start_date: this.state.start_date ? this.state.start_date : new Date(), end_date: new Date(today) })
            }
            this.setState({ subscription_plan: e.target.value })
        }
        else if (type == "password") {
            this.setState({ password: e.target.value })
        }
        else if (type == "username") {
            this.setState({ username: e.target.value })
        }
        else if (type == "end_date") {
            this.setState({ end_date: new Date(e) })
        }
        else if (type == "start_date") {
            this.setState({ start_date: new Date(e) })
        }
        else if (type == "description") {
            this.setState({ description: e.target.value })
        }
        else if (type == "prefix") {
            this.setState({ prefix: e.target.value })
        }
        else if (type == "url") {
            this.setState({ url: e.target.value })
        }
        else if (type == "contact_no") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ contact_no: e.target.value })
            } else if (!e.target.value.match(CommonConfig.RegExp.number)) {
                this.setState({ contact_no: this.state.contact_no })
            } else {
                this.setState({ contact_no: e.target.value })
            }
        }
    }

    handleClose(type) {
        if (type == "addUserModel") {
            this.setState({
                addUserModel: false, type: '',
                subscription_plan: '',
                password: '',
                username: '',
                end_date: '',
                start_date: '',
                description: '',
                prefix: '',
                url: '',
                contact_no: '',
            });
            this.getUserMenu();
        }
        if (type == "userAccessModel") {
            this.setState({ userAccessModel: false });
        }
    }

    handleOpen(ID, type) {
        if (type == "editUser") {
            this.setState({ addUserModel: true });
            this.getUserDetailsByID(ID);
        }
        if (type == "addUserModel") {
            this.setState({ addUserModel: true });
        }
        if (type == "userAccessModel") {
            this.setState({ userAccessModel: true });
            this.getUserAccessByID(ID);
        }


    }
    getUserDetailsByID = (UserID) => {
        let data = {
            UserID: UserID,
        }
        try {
            api.post("admin/getAdminUserByID", data).then(res => {
                if (res.success) {
                    this.setState({
                        subscription_plan: res.data[0].subscription_plan,
                        password: res.data[0].password,
                        username: res.data[0].username,
                        end_date: res.data[0].end_date,
                        start_date: res.data[0].start_date,
                        description: res.data[0].description,
                        prefix: res.data[0].prefix,
                        url: res.data[0].url,
                        contact_no: res.data[0].contact_no,
                        type: "Update"
                    })
                }
            }).catch(err => { })
        } catch (err) { }
    }
    getUserMenu = async () => {
        try {
            let data = {
                UserID: CommonConfig.loginData().contact_no
            }
            await api.post("useraccess/getUserMenu", data)
                .then(async (res) => {
                    let response = await res;
                    if (response.success) {
                        this.setState({
                            ledgerAccess: response.data[4],
                            creditAccess: response.data[2],
                            debitAccess: response.data[3],
                            productAccess: response.data[5],
                            taxAccess: response.data[6],
                            userAccess: response.data[1],
                            importAccess: response.data[0]
                        })
                    } else {

                    }
                })
                .catch((err) => {

                })

        } catch (err) {

        }
    }
    submit = (e) => {
        e.preventDefault();
        let data = {
            subscription_plan: this.state.subscription_plan,
            password: this.state.password,
            username: this.state.username,
            end_date: this.state.end_date ? moment(this.state.end_date).format('YYYY-MM-DD') : '',
            start_date: this.state.start_date ? moment(this.state.start_date).format('YYYY-MM-DD') : '',
            description: this.state.description,
            prefix: this.state.prefix,
            url: this.state.url,
            contact_no: this.state.contact_no,
            ptype: this.state.type ? this.state.type : "Add",
            createdby: CommonConfig.loginData().contact_no,
        }
        try {
            api.post("admin/AddUpdateUser", data).then(res => {
                if (res.success) {
                    if (res.data[0].vMessageType == "success") {
                        CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                        this.setState(initialState);
                        this.setState({ addUserModel: false });
                        this.getUserList();
                        this.getUserMenu();
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
        catch (err) {
            console.log(err)
        }
    }
    actionBodyTemplate = (rowData) => {
        return (
            <div class="d-flex align-item-center">
                {this.state.userAccess.EditAccess == 1 ?
                    <div class="statement-icon" onClick={() => this.handleOpen(rowData.contact_no, "editUser")}>
                        <i class="fas fa-pen text-secondary"></i>
                    </div>
                    :
                    null}

                <div class="statement-icon" onClick={() => this.handleOpen(rowData.contact_no, "userAccessModel")}>
                    <i class="fas fa-user-lock text-secondary"></i>
                </div>
            </div>
            // <>
            //     <i className="fa fa-user" onClick={() => this.handleOpen(rowData.contact_no, "userAccessModel")}></i>&nbsp;&nbsp;&nbsp;
            //     {this.state.userAccess.EditAccess == 1 ?
            //         <i className="fa fa-edit" onClick={() => this.handleOpen(rowData.contact_no, "editUser")}></i>
            //         : null}

            // </>
        )

    }

    render() {
        const { importAccess, ledgerAccess, creditAccess, debitAccess, productAccess, taxAccess,
            userAccess, userAccessModel, userTypeList, addUserModel, menuList, isPasswordShow, subscription_plan, password, username, end_date, start_date, db_name, description, prefix, url, pin, account_status, contact_no, } = this.state;

        return (
            <div className='statement-modal'>
                <div class="modal-header border-bottom-0">

                    <h5 class="modal-title">
                        Manage Users
                    </h5>

                    <div class="d-flex">
                        {userAccess.MenuName == "Users" && userAccess.AddAccess == 1 ?

                            <button type="button" className='btn btn-dark border-light bg-secondary-color' onClick={(e) => this.handleOpen(e, "addUserModel")}><i className="fas fa-plus add-user-icon"></i>Add User</button>

                            : null}
                        <div class="d-flex header-icon card-header-btn">
                            <button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={(e) => this.props.history.push('/dashboard')}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
                {/* <div className='adduser-buttonbox'>
                    {userAccess.MenuName == "Users" && userAccess.AddAccess == 1 ?
                        <div className='add-user-container'>
                            <i className="fa fa-arrow-left" onClick={(e) => this.props.history.push('/dashboard')}><span>Go Back</span></i>
                        </div>
                        : null}
                    <div className='add-user-container'>
                        <button type="button" className='btn btn-primary' onClick={(e) => this.handleOpen(e, "addUserModel")}><i className="fas fa-plus add-user-icon"></i>Add User</button>
                    </div>

                </div> */}
                <div className='modal-body pt-0'>
                    <div class="overflow-auto">
                        <div class="table-area">
                            <div class="statement-table">
                                {userAccess.MenuName == "Users" && userAccess.ViewAccess == 1 ?
                                    <table class="table table-striped borderless">
                                        <thead>
                                            <tr>
                                                <th class="table-first" scope="col">Contact No</th>
                                                <th class="table-first" scope="col">Username</th>
                                                <th class="table-first" scope="col">User Role</th>
                                                <th class="table-fifth-th" scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody id="statement-id" class="table-body">

                                            {userTypeList.length ? userTypeList.map((x) => {
                                                return (
                                                    <tr>
                                                        <td class="table-first">{x.contact_no}</td>
                                                        <td class="table-first">{x.Username}</td>
                                                        <td class="table-first">{x.role}</td>

                                                        <td class="table-fifth-th">{this.actionBodyTemplate(x)}</td>

                                                    </tr>
                                                )
                                            }
                                            ) : null
                                            }



                                        </tbody>
                                    </table>
                                    :
                                    <div>
                                        <span>You do not have permission to view this page</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={userAccessModel} dialogClassName="" className="right profile-modal" onHide={() => this.handleClose("addUserModel")}>
                    <div class="modal-header border-bottom-0">

                        <h5 class="modal-title">
                            User Permission
                        </h5>
                        <div class="d-flex header-icon card-header-btn">
                            <button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={(e) => this.handleClose("userAccessModel")}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body user-modal pb-4 py-0">
                        {
                            menuList.length ? (
                                menuList.map((x, idx) => {
                                    return (
                                        <div class="mb-2">
                                            <div className='list-party-name'>
                                                <label> {x.MenuName}</label>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.AddAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "AddAccess")}
                                                        />
                                                        Add
                                                    </label>
                                                </div>
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.EditAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "EditAccess")}
                                                        />
                                                        Edit
                                                    </label>
                                                </div>
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.ViewAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "ViewAccess")}
                                                        />
                                                        View
                                                    </label>
                                                </div>
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.DeleteAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "DeleteAccess")}
                                                        />
                                                        Delete
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div>
                                    <span>No record found</span>
                                </div>
                            )



                        }
                    </div>
                    <div class="modal-footer" style={{
                        position: 'absolute',
                        bottom: '15px',
                        width: '100%'

                    }}>
                        <div class="text-center">
                            <button type="button" class="btn btn-danger Cancel-btn" id="credit_save_button" onClick={(e) => this.handleClose("userAccessModel")}>Cancel</button>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-success Submit-btn" id="credit_save_button" onClick={(e) => this.AddUserAccess(e)}>Save</button>
                        </div>
                    </div>



                </Modal>

                <Modal show={addUserModel} dialogClassName="full-screen-modal" className="full-screen-modal" onHide={() => this.handleClose("addUserModel")}>
                    <div class="modal-header border-bottom-0">

                        <h5 class="modal-title">
                            Add User
                        </h5>
                        <div class="d-flex header-icon card-header-btn">
                            <button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={() => { this.handleClose("addUserModel") }}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body user-modal pb-4 py-0">
                        {/* <div class="card-body admin-card-area"> */}
                        <form id="user" onSubmit={(e) => {
                            this.submit(e)
                            // this.props.history.push('/admin/dashboard')
                        }}>
                            {/* <div class="container-fluid " id="edit-container"> */}
                            <div class="row">
                                <input id="account_status" name="account_status" value="1" type="hidden" />
                                <div class="col-sm-12 col-md-6">
                                    {/* <div class="admin-card-left"> */}
                                    <div class="card-element">
                                        <div class=""><label for="username">Username</label></div>
                                        <div class="d-flex">
                                            {/* <div class="col-12 col-xs-3 col-sm-3 col-md-3 pb-2 pb-xs-0 pb-md-0 pb-lg-0"> */}
                                            {/* <div class="form-group "> */}
                                            <select autoFocus={true} value={prefix} onChange={(e) => {
                                                this.handleChange(e, "prefix")
                                            }} id="prefix" name="prefix" class="form-control w-25 mr-2">
                                                <option value="">Select Prefix</option>
                                                <option value="Mr">Mr.</option>
                                                <option value="Mrs">Mrs.</option>
                                                <option value="Miss">Miss</option>
                                            </select>
                                            {/* </div> */}
                                            {/* </div>
                                                    <div class="col-12 col-xs-9 col-sm-9 col-md-9"> */}
                                            <input id="username" name="username" type="text" maxlength="20" value={username} className="form-control" onChange={(e) => this.handleChange(e, "username")} />
                                            <div id="user_error" class="invalid-feedback">
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-element">
                                        <div class=""><label for="contact">Contact No.</label></div>
                                        <input id="contact" className="form-control" name="contact_no" type="text" maxLength='10' value={contact_no} onChange={(e) => this.handleChange(e, "contact_no")} />
                                        <div id="contact_error" class="invalid-feedback">
                                        </div>
                                    </div>
                                    <div class="card-element">
                                        <div class=""><label for="password">Password</label></div>
                                        <input className="form-control" id="txtbpassword" name="password" type={isPasswordShow ? "text" : "password"} value={password} onChange={(e) => this.handleChange(e, "password")} />
                                        <input type="checkbox" onClick={(e) => this.setState({ isPasswordShow: e.target.checked })} /> Show Password
                                    </div>

                                    <div class="card-element">
                                        <div class=""><label for="url">URL</label></div>
                                        <input className="form-control" id="url" name="url" type="text" value={url} onChange={(e) => this.handleChange(e, "url")} />
                                    </div>

                                    {/* </div> */}
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    {/* <div class="admin-card-right"> */}

                                    <div class="card-element">
                                        <div class=""><label for="plan">Subscription Plan</label></div>
                                        {/* <div class="form-group"> */}
                                        <select id="sub_plan" name="subscription_plan" class="form-control" value={subscription_plan} onChange={(e) => this.handleChange(e, "subscription_plan")} >
                                            <option value="">Select Plan</option>
                                            <option value="Monthly">Monthly</option>
                                            <option value="Yearly">Yearly</option>
                                        </select>
                                        {/* </div> */}
                                        <div id="subscription_error" class="invalid-feedback" style={{ display: "none" }}>

                                        </div>
                                    </div>

                                    <div class="card-element">
                                        <div class=""><label for="username">Time Line</label></div>
                                        {/* <div class="container-fluid px-0"> */}
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                {/* <input name="start_date" value={start_date} onChange={(e) => this.handleChange(e, "start_date")} type="date" id="plan" placeholder="Start Date" required="" /> */}
                                                {/* <input type="date" id="credit_date" required
                                                    value={start_date}
                                                    onChange={(e) => this.handleChange(e, "start_date")} /> */}
                                                <DatePicker
                                                    ref="picker"
                                                    animation
                                                    editable={false}
                                                    type="input-icon"
                                                    placeholder="Start Date"
                                                    format="DD/MM/YYYY"
                                                    value={start_date}
                                                    onChange={(e) => this.handleChange(e, "start_date")}
                                                />
                                            </div>
                                            <div class="col-sm-12 col-md-6">
                                                {/* <input name="end_date" type="date" value={end_date} onChange={(e) => this.handleChange(e, "end_date")} id="plan1" placeholder="End Date" required="" /> */}
                                                <DatePicker
                                                    animation
                                                    editable={false}
                                                    type="input-icon"
                                                    placeholder="End Date"
                                                    format="DD/MM/YYYY"
                                                    value={end_date} onChange={(e) => this.handleChange(e, "end_date")}
                                                // plugins={[
                                                //     <DatePickerHeader
                                                //         position="top"
                                                //         size="small"
                                                //         style={{ backgroundColor: "steelblue" }}
                                                //     />
                                                // ]}
                                                />
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>

                                    <div class="card-element">
                                        <div class=""><label for="remark">Remark</label>
                                        </div>
                                        <textarea className='form-control' id="remark" name="description" value={description} onChange={(e) => this.handleChange(e, "description")} maxlength="25" rows="6"></textarea>
                                        <div id="remark_error" class="invalid-feedback d-none"></div>
                                    </div>

                                    {/* <div class="text-right my-5">
                                        <button id="submit-btn" class="btn btn-success Submit-btn" type="submit" value="Submit">
                                            Submit
                                        </button>
                                        <button type="button" class="btn btn-danger Cancel-btn ml-3" onClick={() => { this.handleClose("addUserModel") }}>
                                            Cancel
                                        </button>
                                    </div> */}
                                    {/* </div> */}
                                </div>

                            </div>
                            {/* </div> */}
                        </form>

                    </div>
                    <div class="modal-footer">
                        <div class="text-center">
                            <button type="button" class="btn btn-danger Cancel-btn" id="credit_save_button" onClick={() => { this.handleClose("addUserModel") }}>Cancel</button>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-success Submit-btn" id="credit_save_button" onClick={(e) => {
                                this.submit(e)
                                // this.props.history.push('/admin/dashboard')
                            }}>Save</button>
                        </div>
                    </div>
                </Modal>
            </div>
            // <div>
            //     <form>
            //         {
            //             menuList.length ? (
            //                 menuList.map((x, idx) => {
            //                     return (
            //                         <div>

            //                             <>
            //                                 <div className='p-grid p-p-2'>
            //                                     <h5> {x.MenuName}</h5>
            //                                     <label>
            //                                         <input type="checkbox" class="form-check-label"
            //                                             defaultChecked={x.AddAccess == 1 ? true : false}
            //                                             onChange={(e) => this.handleChangeMenu(e, idx, "AddAccess")}
            //                                         />
            //                                         Add
            //                                     </label>

            //                                     <label>
            //                                         <input type="checkbox" class="form-check-label"
            //                                             defaultChecked={x.EditAccess == 1 ? true : false}
            //                                             onChange={(e) => this.handleChangeMenu(e, idx, "EditAccess")}
            //                                         />
            //                                         Edit
            //                                     </label>


            //                                     <label>
            //                                         <input type="checkbox" class="form-check-label"
            //                                             defaultChecked={x.ViewAccess == 1 ? true : false}
            //                                             onChange={(e) => this.handleChangeMenu(e, idx, "ViewAccess")}
            //                                         />
            //                                         View
            //                                     </label>

            //                                     <label>
            //                                         <input type="checkbox" class="form-check-label"
            //                                             defaultChecked={x.DeleteAccess == 1 ? true : false}
            //                                             onChange={(e) => this.handleChangeMenu(e, idx, "DeleteAccess")}
            //                                         />
            //                                         Delete
            //                                     </label>

            //                                 </div>
            //                             </>

            //                         </div>
            //                     )
            //                 })
            //             ) : (
            //                 <div>
            //                     <span>No record found</span>
            //                 </div>
            //             )
            //         }
            //         <div div class="text-center my-5">
            //             <button id="submit-btn" class="btn btn-success Submit-btn" type="submit" value="Submit" onClick={(e) => this.AddUserAccess()}>
            //                 Add permission
            //             </button>
            //         </div>
            //     </form>
            // </div>

        )
    }
}
