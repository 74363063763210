import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Header from '../components/Header/Header.js';
import routes from "../routes.js";



export default function Dashboard(props) {
    const { ...rest } = props;
    React.useEffect(() => {

        return () => {
        }
    }, [])

    const isAuthorized = localStorage.getItem("isAuthorized");

    const getRoutes = routes => {
        if (window.location.pathname === "/user/login") {
            document.body.classList.add('login-container');
        } else {
            document.body.classList.remove('login-container');
        }
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if ((prop.layout === "/user" || prop.layout === "/") && isAuthorized && prop.isAccessRequired) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else if ((prop.layout === "/user" || prop.layout === "/") && !prop.isAccessRequired) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return (
        window.location.pathname === "/user/dashboard" ?
            <div className="main-container">
                <Header {...rest} />
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/user/login" />
                </Switch>
            </div>
            :
            <div>
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/user/login" />
                </Switch>
            </div>
    );
}
