import Swal from 'sweetalert2';
export default class CommonConfig {

    static isEmpty = function (value) {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            if (typeof value === 'string') {
                return value.trim() === "";
            } else {
                return false;
            }
        }
    }

    static isObjectEmpty = function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    static filterTwoArray = function (data, filters) {
        return data.filter(e => {
            try {
                filters.forEach(o => {
                    Object.keys(o).forEach(key => {
                        if (e[key] !== o[key]) throw new 1;
                    });
                });

                return true;
            } catch (e) {
                return false;
            }
        });
    }

    static getDatesBetweenDates = function (startDate, endDate) {
        let dates = []
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
            dates = [...dates, new Date(theDate)]
            theDate.setDate(theDate.getDate() + 1)
        }
        // dates = [...dates, endDate]
        dates.splice(0, 1)
        return dates
    }

    static amountValidate = function (e) {
        var t = e;
        e = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t.indexOf("-") != -1 ? t.substr(1, t.length) : t;
        console.log("t", e)
        return e;
    }

    static addDaysToDates = function (date, noOfDays) {
        var theDate = date;
        theDate.setDate(theDate.getDate() + noOfDays);
        return theDate;
    }

    static dateFormat = {
        dateTime: 'MM/DD/YYYY hh:mm:ss A',
        dateOnly: 'MM/DD/YYYY',
        dateFirst: 'DD/MM/YYYY',
        forDatePicker: 'DD-MM-YYYY',
        yearOnly: 'YYYY',
        dbDateTime: 'YYYY-MM-DD HH:mm:ss',
        timeOnly: 'HH:mm'
    }

    static loginData = function () {
        if (localStorage.getItem('loginData')) {
            return JSON.parse(localStorage.getItem('loginData'));
        }
        else {
            return ""
        }
    }

    static loginAdminData = function () {
        if (localStorage.getItem('loginAdminData')) {
            return JSON.parse(localStorage.getItem('loginAdminData'));
        }
        else {
            return ""
        }
    }

    static showMessage = function (title, icon) {
        const Toast = Swal.mixin({
            position: 'top-end',
            toast: true,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
                title: 'd-flex w-100'
            }
        })
        return (
            Toast.fire({
                icon: icon,
                title: title
            })
        )
    }

    static showDeleteConfirm = function () {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger mr-2'
            },
            buttonsStyling: false
        })
        return (
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            })
        )
    }

    static userCountryData = function () {
        if (localStorage.getItem('UserCountryDetails')) {
            return JSON.parse(localStorage.getItem('UserCountryDetails'));
        }
        else {
            return ""
        }
    }

    static getAddressComponentsfromGoogle = (lat, lng, entityname) => {
        // entityname can be route , postal_town , country
        const google = window.google;
        var geocoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(lat, lng);
        var output = '';
        return geocoder.geocode({ 'latLng': latlng }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                //formatted address


                for (var i = 0; i < results[0].address_components.length; i++) {
                    for (var b = 0; b < results[0].address_components[i].types.length; b++) {

                        //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                        if (results[0].address_components[i].types[b] == entityname) {
                            //this is the object you are looking for
                            // return console.log("common center",results[0].address_components[i].long_name)
                            output = results[0].address_components[i].long_name;
                            // break;
                        }
                    }
                }
                return output;
            } else {
                // alert("Geocoder failed due to: " + status);
            }
        })
    }

    static getUserAccess = function (moduleName) {
        if (localStorage.getItem('loginData')) {
            let data = JSON.parse(localStorage.getItem('loginData')).userModuleAccess;

            let moduleAccess = data.find(x => x.MenuKey === moduleName);

            return moduleAccess

        } else {
            return ""
        }
    }

    static randomString = function (length) {
        // var length = 32; 
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    static ExcelDateToJSDate = function (serial) {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);

        var fractional_day = serial - Math.floor(serial) + 0.0000001;

        var total_seconds = Math.floor(86400 * fractional_day);

        var seconds = total_seconds % 60;

        total_seconds -= seconds;

        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    static filterCaseInsensitive = function (filter, row) {
        const id = filter.pivotId || filter.id;
        const content = row[id];

        if (typeof content !== 'undefined') {
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
    }

    static getIndianFormatAmount = function (amount) {
        var x = amount;
        x = x.toString();
        var afterPoint = '';
        if (x.indexOf('.') > 0)
            afterPoint = x.substring(x.indexOf('.'), x.length);
        x = Math.floor(x);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    }

    static RegExp = {
        number: /^[0-9\b]+$/,
        onlyNumber: /[a-zA-Z~`!@#$%^&*()_+=-{}|:"<>?,;']+$/,
        // onlyDecimal: /^[0-9]+(\.[0-9][0-9])?$/,
        onlyDecimal: /^\d*\.?\d{0,2}$/,
        phoneNumber: /^([0-9]+\s?)*$/g,
        // email : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/ig,
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        companyName: /[!@~`#$%^&*()_+\-=\]{};':"\\|,.<>?]/,
        zipCode: /^([a-zA-Z0-9]+\s?)*$/g,
        regExpNumber: /[0-9]/g,
        regExpUpperCase: /[A-Z]/g,
        regExpLowerCase: /[a-z]/g,
        phone: /^[0-9]{10}$/,
        regExpSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/g,
        RateRegex: /^(\$|)([1-9]\d{0,3}(\.\d{3})*|([1-9]\d*))(\.\d{3})?$/,
    }

}