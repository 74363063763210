import React, { Component } from 'react';
import '../../../assets/css/adminDashboard.scss';
import api from "../../../utils/apiClient";
import CommonConfig from "../../../utils/constant";
import DatePicker from "react-multi-date-picker"
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header"
import moment from 'moment';

const initialState = {
    showCreditModal: false,
    CreditEntry: [],
    DebitEntry: [],
    subscription_plan: 'Yearly',
    password: CommonConfig.randomString(32),
    username: CommonConfig.randomString(6),
    end_date: '',
    start_date: '',
    description: '',
    prefix: '',
    url: CommonConfig.randomString(6),
    contact_no: '',
    isPasswordShow: false,
    menuList: []
}

export default class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }


    componentDidMount() {
        var today = this.state.start_date ? this.state.start_date : new Date();
        today.setDate(today.getDate() + 365);
        this.setState({ start_date: this.state.start_date ? this.state.start_date : new Date(), end_date: new Date(today) });
        this.getMenuMaster();
    }


    submit = (e) => {
        e.preventDefault();
        let data = {
            subscription_plan: this.state.subscription_plan,
            password: this.state.password,
            username: this.state.username,
            end_date: this.state.end_date ? moment(this.state.end_date).format('YYYY-MM-DD') : '',
            start_date: this.state.start_date ? moment(this.state.start_date).format('YYYY-MM-DD') : '',
            description: this.state.description,
            prefix: this.state.prefix,
            url: this.state.url,
            contact_no: this.state.contact_no,
            ptype: "Add",
            createdby: CommonConfig.loginData.contact_no
        }
        try {
            api.post("admin/AddUpdateUser", data).then(res => {
                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                    if (res.data[0].vMessageType == "success") {
                        this.setState(initialState);
                        this.props.history.push('/admin/dashboard')
                    }
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    handleChange = (e, type) => {
        if (type == "subscription_plan") {
            if (e.target.value == "Monthly") {
                var today = this.state.start_date ? this.state.start_date : new Date();
                today.setDate(today.getDate() + 30);
                // console.log(today.getFullYear() + "-" + today.getMonth() + "-" + (today.getDate() + 30))
                this.setState({ start_date: this.state.start_date ? this.state.start_date : new Date(), end_date: new Date(today) })
            } else if (e.target.value == "Yearly") {
                var today = this.state.start_date ? this.state.start_date : new Date();
                today.setDate(today.getDate() + 365);
                this.setState({ start_date: this.state.start_date ? this.state.start_date : new Date(), end_date: new Date(today) })
            }
            this.setState({ subscription_plan: e.target.value })
        }
        else if (type == "password") {
            this.setState({ password: e.target.value })
        }
        else if (type == "username") {
            this.setState({ username: e.target.value })
        }
        else if (type == "end_date") {
            this.setState({ end_date: new Date(e) })
        }
        else if (type == "start_date") {
            this.setState({ start_date: new Date(e) })
        }
        else if (type == "description") {
            this.setState({ description: e.target.value })
        }
        else if (type == "prefix") {
            this.setState({ prefix: e.target.value })
        }
        else if (type == "url") {
            this.setState({ url: e.target.value })
        }
        else if (type == "contact_no") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ contact_no: e.target.value })
            } else if (!e.target.value.match(CommonConfig.RegExp.phoneNumber)) {
                this.setState({ contact_no: this.state.contact_no })
            } else {
                this.setState({ contact_no: e.target.value })
            }
        }
    }

    handleChangeMenu = (e, idx, type) => {
        if (type === "AddAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["AddAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "EditAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["EditAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "ViewAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["ViewAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "DeleteAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["DeleteAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        }
    };
    getMenuMaster = async () => {
        try {
            await api
                .post('useraccess/getMenuMaster')
                .then(async (res) => {
                    let response = await res;
                    if (response.success) {

                        this.setState({
                            menuList: response.data,
                        });
                        console.log(typeof (this.state.menuList
                        ))
                    } else {
                        CommonConfig.showMessage(response.data[0].vMessage, response.data[0].vMessageType);
                    }
                })
                .catch((err) => {
                });
        } catch (err) {
        }
    };
    AddUserAccess = (e) => {
        e.preventDefault();
        try {
            let data = {
                SecurityUserID: this.state.contact_no,
                AccessList: this.state.menuList,
                UserID: this.state.contact_no,
            }
            api.post("useraccess/addUpdateUserAccess", data).then((res) => {
                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                }
            }).catch(err => {

            })
        } catch (err) {

        }
    }

    render() {
        const { menuList, isPasswordShow, subscription_plan, password, username, end_date, start_date, db_name, description, prefix, url, pin, account_status, contact_no, } = this.state;
        const { state } = this.props.location
        window.onkeydown = (event) => {
            if (event.key === "Escape" || event.which === 27) {
                // write your logic here.
                this.props.history.push('/admin/dashboard')
            }
        }
        return (
            <div>
                <div class="admin-menu">
                    <div>
                        <div class="admin-menu-element1">
                            <a href="javascript:;" onClick={() => this.props.history.push('/admin/createuser')}>
                                <div class="menu-detail">Create User </div><i class="fas fa-user"></i>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div class="admin-menu-element2 mt-2">
                            <a href="javascript:;" onClick={() => this.props.history.push('/admin/dashboard')}>
                                <div class="menu-detail">User list </div><i class="fas fa-list-ul"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="admin-container">
                    <div class="card admin-card-body">
                        <div class="card-header admin-card-body-header">
                            <div class="d-flex align-items-center justify-content-between">
                                <div><span id="action-header">Create User</span></div>
                                <div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body admin-card-area">
                            <form id="user" onSubmit={(e) => {
                                this.submit(e)
                                // this.props.history.push('/admin/dashboard')
                            }}>
                                <div class="container-fluid " id="edit-container">
                                    <div class="row">
                                        <input id="account_status" name="account_status" value="1" type="hidden" />
                                        <div class="col-sm-12 col-md-6">
                                            <div class="admin-card-left">
                                                <div class="card-admin-element">
                                                    <div class="pb-2"><label for="username">Username</label></div>
                                                    <div class="row">
                                                        <div class="col-12 col-xs-3 col-sm-3 col-md-3 pb-2 pb-xs-0 pb-md-0 pb-lg-0">
                                                            <div class="form-group ">
                                                                <select autoFocus={true} value={prefix} onChange={(e) => {
                                                                    this.handleChange(e, "prefix")
                                                                }} id="prefix" name="prefix" class="form-control">
                                                                    <option value="">Select Prefix</option>
                                                                    <option value="Mr">Mr.</option>
                                                                    <option value="Mrs">Mrs.</option>
                                                                    <option value="Miss">Miss</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-xs-9 col-sm-9 col-md-9">
                                                            <input id="username" name="username" type="text" maxlength="20" value={username} onChange={(e) => this.handleChange(e, "username")} />
                                                            <div id="user_error" class="invalid-feedback">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-admin-element">
                                                    <div class="pb-2"><label for="contact">Contact No.</label></div>
                                                    <input id="contact" name="contact_no" type="text" value={contact_no} onChange={(e) => this.handleChange(e, "contact_no")} />
                                                    <div id="contact_error" class="invalid-feedback">
                                                    </div>
                                                </div>
                                                <div class="card-admin-element">
                                                    <div class="pb-2"><label for="password">Password</label></div>
                                                    <input id="txtbpassword" name="password" type={isPasswordShow ? "text" : "password"} value={password} onChange={(e) => this.handleChange(e, "password")} />
                                                    <input type="checkbox" onClick={(e) => this.setState({ isPasswordShow: e.target.checked })} /> Show Password
                                                </div>

                                                <div class="card-admin-element">
                                                    <div class="pb-2"><label for="url">URL</label></div>
                                                    <input id="url" name="url" type="text" value={url} onChange={(e) => this.handleChange(e, "url")} />
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="admin-card-right">

                                                <div class="card-admin-element">
                                                    <div class="pb-2"><label for="plan">Subscription Plan</label></div>
                                                    <div class="form-group">
                                                        <select id="sub_plan" name="subscription_plan" class="form-control" value={subscription_plan} onChange={(e) => this.handleChange(e, "subscription_plan")} >
                                                            <option value="">Select Plan</option>
                                                            <option value="Monthly">Monthly</option>
                                                            <option value="Yearly">Yearly</option>
                                                        </select>
                                                    </div>
                                                    <div id="subscription_error" class="invalid-feedback" style={{ display: "none" }}>

                                                    </div>
                                                </div>

                                                <div class="card-admin-element">
                                                    <div class="pb-2"><label for="username">Time Line</label></div>
                                                    <div class="container-fluid px-0">
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6">
                                                                {/* <input name="start_date" value={start_date} onChange={(e) => this.handleChange(e, "start_date")} type="date" id="plan" placeholder="Start Date" required="" /> */}
                                                                {/* <input type="date" id="credit_date" required
                                                                    value={start_date}
                                                                    onChange={(e) => this.handleChange(e, "start_date")} /> */}
                                                                <DatePicker
                                                                    ref="picker"
                                                                    animation
                                                                    editable={false}
                                                                    type="input-icon"
                                                                    placeholder="Start Date"
                                                                    format="DD/MM/YYYY"
                                                                    value={start_date}
                                                                    onChange={(e) => this.handleChange(e, "start_date")}
                                                                />
                                                            </div>
                                                            <div class="col-sm-12 col-md-6">
                                                                {/* <input name="end_date" type="date" value={end_date} onChange={(e) => this.handleChange(e, "end_date")} id="plan1" placeholder="End Date" required="" /> */}
                                                                <DatePicker
                                                                    animation
                                                                    editable={false}
                                                                    type="input-icon"
                                                                    placeholder="End Date"
                                                                    format="DD/MM/YYYY"
                                                                    value={end_date} onChange={(e) => this.handleChange(e, "end_date")}
                                                                // plugins={[
                                                                //     <DatePickerHeader
                                                                //         position="top"
                                                                //         size="small"
                                                                //         style={{ backgroundColor: "steelblue" }}
                                                                //     />
                                                                // ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card-admin-element">
                                                    <div class="pb-2"><label for="remark">Remark</label>
                                                    </div>
                                                    <textarea id="remark" name="description" value={description} onChange={(e) => this.handleChange(e, "description")} maxlength="25" rows="1"></textarea>
                                                    <div id="remark_error" class="invalid-feedback d-none"></div>
                                                </div>

                                                <div class="text-center my-5">
                                                    <button id="submit-btn" class="btn btn-success Submit-btn" type="submit" value="Submit">
                                                        Submit
                                                    </button>
                                                    <button type="button" class="btn btn-danger Cancel-btn ml-3" onClick={() => { this.props.history.push('/admin/dashboard') }}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
