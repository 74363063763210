import React, { Component } from 'react'
import lockScreenBg from '../../assets/img/lockScreen-bg.png';
import img2 from '../../assets/img/img2.jpg';
import CommonConfig from '../../utils/constant';
import Swal from 'sweetalert2';
import api from "../../utils/apiClient";

export default class LockScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Pin: ''
        }
    }

    componentDidMount(){
        if(CommonConfig.isEmpty(localStorage.getItem('IsUserLocked'))){
            this.props.history.goBack(-1)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            UserID: CommonConfig.loginData().contact_no,
            Pin: this.state.Pin
        }
        try {
            api.post("authentication/lockscreen", data).then(res => {
                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                    if (res.data[0].vIsSuccess === "1") {
                        localStorage.removeItem('IsUserLocked')
                        this.props.history.goBack(-1);
                    }
                } else {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    handleChange = (e) => {
        if (CommonConfig.isEmpty(e.target.value)) {
            this.setState({ Pin: e.target.value })
        }
        else if (!e.target.value.match(CommonConfig.RegExp.number)) {
            this.setState({ Pin: this.state.Pin })
        }
        else if (e.target.value.length > 4) {
            this.setState({ Pin: this.state.Pin })
        } else {
            this.setState({ Pin: e.target.value })
        }
    }

    render() {
        return (
            <div class="main-lockscreen-container">
                <div class="lock_screen"
                    style={{ backgroundImage: `url(${lockScreenBg})` }}
                >
                    <div class="login-lockscreen-section">
                        <div class="p-4 pt-0">
                            <div class="main-img">
                                <img src={img2} alt="logo-image" />
                            </div>
                            <div class="main-header">
                                {CommonConfig.loginData.username}
                            </div>
                            <div class="login-lockscreen-area">
                                <form autocomplete="off" onSubmit={(e) => this.handleSubmit(e)}>

                                    <div class="input-lockscreen-area one" id="lock-inp">
                                        <div class="i">
                                            <i class="fas fa-user"></i>
                                        </div>
                                        <div class="sectionarea">
                                            <h5>Enter Your Pin</h5>
                                            <input type="text" id="pin" name="username" class="input" value={this.state.Pin} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </div>


                                    <button type="submit" class="login-lockscreen-btn">
                                        SUBMIT
                                    </button>

                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
