import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
// import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import UserLayout from "./layouts/User.js";
import AdminLayout from "./layouts/Admin.js";
// const history = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter>
      <Switch>
        <Route path="/user" component={UserLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/user/login" />
      </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
reportWebVitals();

