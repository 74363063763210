import Login from '../src/components/Admin/Login/Login.js';
import Dashboard from '../src/components/Admin/Dashboard/Dashboard.js';
import AddUser from '../src/components/Admin/User/AddUser.js';
import EditUser from '../src/components/Admin/User/EditUser.js'
var dashRoutes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/admin",
        isAccessRequired: false
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/admin",
        isAccessRequired: true
    },
    {
        path: "/createuser",
        name: "AddUser",
        component: AddUser,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/admin",
        isAccessRequired: true
    },
    {
        path: "/edituser",
        name: "EditUser",
        component: EditUser,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/admin",
        isAccessRequired: true
    },
]

export default dashRoutes;