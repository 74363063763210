import React, { Component } from 'react'
import '../../../assets/css/adminDashboard.scss';
import logo from '../../../assets/img/AccountingLogo.png';
import profiledummyimg from '../../../assets/img/img1.jpeg';
import { Dropdown, Modal } from 'react-bootstrap';
import CommonConfig from '../../../utils/constant';
import api from "../../../utils/apiClient";
import moment from 'moment';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{color : "unset",border:"none"}}
    >
        {children}
    </span>
));

const initialState = {
    showProfileModal: false,
    UserName: '',
    Contact_No: '',
    Start_Date: '',
    end_Date: '',
    Sub_plan: '',
    showPasswordChange: false,
    showProfile: true,
    CurrentPassword:'',
	ConfirmPassword:'',
    NewPassword:'',

}

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {

        this.getProfileDetail();
    }
    getProfileDetail = () => {

        let data = {
            UserID: CommonConfig.loginAdminData().contact_no,
        }
        try {
            api.post("profile/getProfileDetails", data).then(res => {
                if (res.success) {
                    this.setState({
                        UserName: res.data[0].username,
                        Contact_No: res.data[0].contact_no,
                        Start_Date: res.data[0].start_date,
                        end_Date: res.data[0].end_date,
                        Sub_plan: res.data[0].subscription_plan,

                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }
    handleLogOut = () => {
        window.location.href = window.location.origin + '/admin/login'
        localStorage.removeItem('loginAdminData');
		localStorage.removeItem('isAdminAuthorized');
    }

    handleOpen = (type) => {
        document.body.click();
        if (type === "Profile") {
            this.setState({ showProfileModal: true })
        }
    }

    handleClose = (type) => {
        document.body.click();
        if (type === "Profile") {
            this.setState({ showProfileModal: false })
        }
    }
    // ===========================================================================Changepassword
	handlePwdChange = (e, type) => {
        if (type === "CurrentPassword") {
            this.setState({ CurrentPassword: e.target.value })
        }
        if (type === "ConfirmPassword" ) {
            this.setState({ ConfirmPassword: e.target.value }) 
        }
		if (type === "NewPassword" ) {
            this.setState({ NewPassword: e.target.value }) 
        }
    }
	handleChangePassword = (e) => {
        e.preventDefault();

        let data = {
            UserID: CommonConfig.loginAdminData().contact_no,
            CurrentPassword: this.state.CurrentPassword,
			NewPassword : this.state.NewPassword === this.state.ConfirmPassword ?  this.state.ConfirmPassword :" " ,
        }
        try {
            api.post("authentication/userChangePassword", data).then(res => {
				
				if (res.success) {
                    CommonConfig.showMessage(res.message, res.messagetype);
				this.setState({showProfileModal: false, })
                } else {
                    CommonConfig.showMessage(res.message, res.messagetype);
					this.setState({ ConfirmPassword: "" , CurrentPassword :"",NewPassword:'',showProfileModal:true})
                }
            }).catch(err => { })
        }
        catch (err) { }
    }  
	// ===========================================================================ChangePassword

    render() {
        const { showProfileModal, UserName, Contact_No, Start_Date, end_Date, Sub_plan, showPasswordChange, showProfile ,ConfirmPassword,CurrentPassword,
        NewPassword} = this.state

        return (
            <div class="admin-header">
                <div class="d-flex justify-content-between h-100">

                    <div class="admin-header-logo">
                        <img src={logo} alt="" />
                    </div>
                    <div class="header-icon align-self-center mx-2 d-flex justify-content-center" style={{color : "unset",border:"none"}}>
                        <div class="dropdown admin-profile-dropdown align-self-center">
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle}>
                                    <span style={{color : "unset",border:"none"}} id="dropBtn" className="d-inline-block mr-2" role="button" data-toggle="dropdown" aria-expanded="false">
                                        <div className="user-profile align-self-center mx-1">
                                            <img src={profiledummyimg} alt="User profile" />
                                        </div> <span className="header-button-res-block user-name pb-2">{UserName}</span>
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu py-0">
                                    <li className="header-button-res text-center">{UserName}
                                        <hr className="my-1" />
                                    </li>
                                    <li className="header-button-res profile-drop-button">
                                        <div className="header-icon align-self-center d-flex justify-content-center text-nowrap">
                                            <button className="text-left" onclick="window.location.href=&quot;lockScreen&quot;">
                                                <i className="mr-2 fas fa-lock align-self-center"></i>
                                                Lock Screen
                                            </button>
                                        </div>
                                    </li>
                                    <li className="header-button-res profile-drop-button">
                                        <div className="header-icon align-self-center d-flex justify-content-center text-nowrap">
                                            <button className="text-left" onclick="fullScreen()">
                                                <i className="mr-2 fas fa-expand align-self-center"></i>
                                                Full Screen
                                            </button>
                                        </div>
                                    </li>
                                    <li className="profile-drop-button">
                                        <button type="button" className="align-self-center text-left" onClick={() => this.handleOpen('Profile')}>
                                            <i className="mr-2 fas fa-user"></i>
                                            Profile
                                        </button>
                                    </li>
                                    <li className="profile-drop-button">
                                        <button type="button" className="align-self-center text-left" onClick={() => this.handleLogOut()}>
                                            <i className="mr-2 fas fa-sign-out-alt"></i>
                                            Log Out
                                        </button>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {/* Profile Modal */}
                <Modal show={showProfileModal} dialogClassName="full-screen-modal modal-dialog-centered" className="right profile-modal" onHide={() => this.handleClose("Profile")}>

                  
					<div className="modal-header pb-1 border-bottom-0">
						<h5 className="modal-title">Profile</h5>
						<div className="d-flex header-icon card-header-btn">
							<button type="button" className="align-self-center" aria-label="Close" onClick={() => {
								this.handleClose("Profile")
								this.setState({ showPasswordChange: false })
								this.setState({ showProfile: true })
							}}>
								<i className="fas fa-times"></i>
							</button>
						</div>
					</div>
					<div className="modal-body pt-0">
						<div className="Profile-section">

							<div className="base-profile">
								<div className="profile-img" id="setimg"
								style={{ backgroundImage: `url(${profiledummyimg})` }}
								>
								</div>
							</div>
							{showProfile === true ?
								<div>
									<div className="d-flex justify-content-center">
										<div className="profile-information">

											<div className="basic-info">
												<label className="mr-2">Username:</label> {UserName}
											</div>
											<div className="basic-info">
												<label className="mr-2">Contact No:</label> {Contact_No}
											</div>
											{/* <div className="basic-info">
												<label className="mr-2">Start Date:</label>
												<span> {Start_Date ? moment(Start_Date).format('DD MMM YYYY') : ''}</span>
											</div>
											<div className="basic-info">
												<label className="mr-2">End Date:</label>
												<span> {end_Date ? moment(end_Date).format('DD MMM YYYY') : ''}</span>
											</div>
											<div className="basic-info">
												<label className="mr-2">Subscription Plan:</label>
												{Sub_plan}
											</div> */}
										</div>
									</div>
									<div className="text-center header-icon">
										<button type="button" className="btn-hover-effect w-auto" id="changePassword" onClick={() => {
											this.setState({ showPasswordChange: true })
											this.setState({ showProfile: false })
										}}>
											<i className="fas fa-lock"></i> Change Password
										</button>
									</div> </div>
								: null}
							{showPasswordChange === true ? <div>
								<div className="text-center header-icon">
										<button type="button" className="btn-hover-effect w-auto" id="changePassword" onClick={() => {
										this.setState({ showPasswordChange: false })
										this.setState({ showProfile: true })
									}}>
											<i className="fas fa-user"></i> Profile
										</button>
									</div>
								{/* <div id="passwordArea" className="change-password"> */}
									<div className="card-element">
										<div className="pb-2">
											<label className="username">Current Password</label>
										</div>
										<input type="password" value={CurrentPassword} onChange={(e) => this.handlePwdChange(e, "CurrentPassword")} id="password"/>
									</div>
									<div className="card-element">
										<div className="pb-2">
											<label className="username">New Password</label>
										</div>
										<input type="password" id="new_pass" value={NewPassword} onChange={(e) => this.handlePwdChange(e, "NewPassword")} id="password" />
									</div>
									<div className="card-element">
										<div className="pb-2">
											<label className="username">Confirm New Password</label>
										</div>
										<input type="password" id="new_confirm_pass"value={ConfirmPassword} onChange={(e) => this.handlePwdChange(e, "ConfirmPassword")} id="password" />
									</div>
									<div className="text-center">
										<button type="button" className="Submit-btn my-2" onClick={(e) => this.handleChangePassword(e)}id="change_passsword_submit">
											Submit</button>
									</div>
								{/* </div> */}
							</div> : null}
						</div>
					</div>

                </Modal>

            </div>

        )
    }
}


