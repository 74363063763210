import React, { Component, PureComponent } from 'react';
import moneycoming from '../../assets/img/money-coming.svg';
import moneygoing from '../../assets/img/money-going.svg';
import emptyCredit from '../../assets/img/empty-credit.svg';
import emptyDebit from '../../assets/img/empty-debit.svg';
import { Button, Modal } from 'react-bootstrap';
import api from "../../utils/apiClient";
import moment from "moment";
import CommonConfig from '../../utils/constant';
import '../../assets/css/stylesheet.scss';
import Swal from 'sweetalert2';
// import DatePicker from 'react-date-picker';
import Select, { components } from 'react-select';
import DatePicker from "react-multi-date-picker"
import { Chart } from "react-google-charts";
import { useRef } from 'react';
import * as jsPDF from 'jspdf';
const initialState = {
    showCreditDebitModal: false,
    showLedgerAddModal: false,
    showCreditModal: false,
    showDebitModal: false,
    showLedgerModal: false,
    showProductModal: false,
    showEditCreditModal: false,
    showEditDebitModal: false,
    DebitCurrentTotal: '',
    CreditCurrentTotal: '',
    CreditEntry: [],
    DebitEntry: [],
    LedgerList: [],
    CreditDebitStatement: [],
    DebitDEbitStatement: [],
    created_on: new Date(),
    requested_to: '',
    credit_amount: '',
    debit_amount: '',
    description: '',
    status: '',
    edited_amount_added: '',
    edited_amount_requested: '',
    LedgerStatementArr: [],
    LedgerStatement: '',
    ledger_username: '',
    ProductStatementArr: [],
    ProductList: [],
    TaxtList: [],
    TransactionProductDetailArr: [],
    ProductName: '',
    Quantity: '',
    Rate: '',
    Remark: '',
    TaxArr: [
        {
            TaxID: '',
            TaxName: '',
            Value: '',
            Amount: ''
        }
    ],
    IsTaxSelected: false,
    IsProductSelected: false,
    edit_created_on: '',
    edit_amount: '',
    edit_description: '',
    edit_status: '',
    editTransactionID: '',
    IsOverallDeleteSelected: false,
    IsOverallDeleteSelected1: false,
    PartyID: '',
    StartDate: '',
    EndDate: '',
    Other: '',
    Description: '',
    LedgerID: '',
    summaryDetails: {},
    shouldOpenCalendar: true,

    isAmountAdd: false,

    isType: 0,
    creditDebitArr: [],
    creditDebitList: [],
    PartyName: '',
    ContactNo: '',
    Menus: [],
    ledgerAccess: [],
    creditAccess: [],
    debitAccess: [],
    productAccess: [],
    taxAccess: [],
    userAccess: [],
    importAccess: [],
    ctrlArray: [],
    TaxSearchStringTo: '',
    TaxSearchStringFrom: '',
    creditData: []
}
// const CustomOption = innerProps => {

//     return (
//         <components.Option {...innerProps}>
//             <h1
//                 style={{ backgroundColor: innerProps.isFocused ? "yellow" : "inherit" }}
//                 {...innerProps}
//                 ref={innerProps.innerRef}
//             >
//                 {innerProps.label}
//             </h1>
//         </components.Option>
//     );
// };
export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCreditEntry();
        this.getDebitEntry();
        this.getLedgerList();
        this.getProductList();
        this.getTaxList();
        this.getSummary();
        this.getUserMenu();
        document.addEventListener('keydown', this.handleKeyboardEvents);
        // this.props.fun();
    }

    handleKeyboardEvents = (event) => {

        var controllArray = this.state.ctrlArray;

        if (controllArray.length < 2) {
            controllArray.push(event.which || event.keyCode)
            this.setState({ ctrlArray: controllArray });
        } else {
            this.setState({ ctrlArray: [] });
            controllArray = [];
        }
        if (controllArray.length > 1) {
            if (controllArray[0] == 9 && controllArray[1] == 67) {
                this.handleOpen("Credit");
            }
        }
        if (controllArray.length > 1) {
            if (controllArray[0] == 9 && controllArray[1] == 68) {
                this.handleOpen("Debit");
            }
        }
    }
    getSummary = () => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
            PartyID: CommonConfig.isObjectEmpty(this.state.PartyID) ? '' : this.state.PartyID.value,
            StartDate: CommonConfig.isEmpty(this.state.StartDate) ? '' : moment(this.state.StartDate).format('YYYY-MM-DD'),
            EndDate: CommonConfig.isEmpty(this.state.EndDate) ? '' : moment(this.state.EndDate).format('YYYY-MM-DD'),
            Other: this.state.Other,
        }
        try {
            api.post("filter/getDashboardSummaryDetails", data).then(res => {
                if (res.success) {

                    this.setState({
                        summaryDetails: res.data.length ? res.data[0] : {}
                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getTaxList = () => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
        }
        try {
            api.post("tax/ViewTax", data).then(res => {
                if (res.success) {
                    this.setState({
                        TaxtList: res.data
                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    DeleteCreditTransactionList = () => {
        var ListIDs = this.state.CreditEntry.filter(x => x.isDeleteSelected === true);
        if (ListIDs.length) {
            CommonConfig.showDeleteConfirm().then((result) => {
                if (result.isConfirmed) {

                    let data = {
                        added_by: CommonConfig.loginData().contact_no,
                        ListIDs: ListIDs.map(x => { return x.list_id }).join(),
                    }
                    try {
                        api.post("statement/DeleteTransactionList", data).then(res => {
                            if (res.success) {
                                CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                                this.setState(initialState)
                                this.getCreditEntry();
                                this.getDebitEntry();
                                this.getLedgerList();
                                this.getProductList();
                                this.getTaxList();
                                this.getSummary();
                            }
                        }).catch(err => { })
                    }
                    catch (err) { }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.setState(initialState)
                    this.getCreditEntry();
                    this.getDebitEntry();
                    this.getLedgerList();
                    this.getProductList();
                    this.getTaxList();
                    this.getSummary();
                }
            })
        }
        else {
            CommonConfig.showMessage("Please select atleast one entry", "warning");
        }
    }

    DeleteDebitTransactionList = () => {
        var ListIDs = this.state.DebitEntry.filter(x => x.isDeleteSelected === true);
        if (ListIDs.length) {
            CommonConfig.showDeleteConfirm().then((result) => {
                if (result.isConfirmed) {

                    let data = {
                        added_by: CommonConfig.loginData().contact_no,
                        ListIDs: ListIDs.map(x => { return x.list_id }).join(),
                    }
                    try {
                        api.post("statement/DeleteTransactionList", data).then(res => {
                            if (res.success) {
                                CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                                this.setState(initialState)
                                this.getCreditEntry();
                                this.getDebitEntry();
                                this.getLedgerList();
                                this.getProductList();
                                this.getTaxList();
                                this.getSummary();
                            }
                        }).catch(err => { })
                    }
                    catch (err) { }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.setState(initialState)
                    this.getCreditEntry();
                    this.getDebitEntry();
                    this.getLedgerList();
                    this.getProductList();
                    this.getTaxList();
                    this.getSummary();
                }
            })
        }
        else {
            CommonConfig.showMessage("Please select atleast one entry", "warning");
        }
    }

    DeleteTransactionDetail = (requested_to, TransactionID, credit_amount, debit_amount) => {

        CommonConfig.showDeleteConfirm().then((result) => {
            if (result.isConfirmed) {

                let data = {
                    added_by: CommonConfig.loginData().contact_no,
                    requested_to: requested_to,
                    TransactionID: TransactionID,
                    credit_amount: credit_amount,
                    debit_amount: debit_amount,


                }
                try {
                    api.post("statement/DeleteTransactionDetail", data).then(res => {
                        if (res.success) {
                            CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                            this.setState(initialState)
                            this.getLedgerStatement(requested_to)
                            this.getCreditEntry();
                            this.getDebitEntry();
                            this.getLedgerList();
                            this.getProductList();
                            this.getTaxList();
                            this.getSummary();
                        }
                    }).catch(err => { })
                }
                catch (err) { }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // this.setState(initialState);
                // this.getCreditEntry();
                // this.getDebitEntry();
                // this.getLedgerList();
                // this.getProductList();
                // this.getTaxList();
            }
        })

    }

    getProductList = () => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
        }
        try {
            api.post("product/getProductList", data).then(res => {
                if (res.success) {
                    this.setState({
                        ProductList: res.data
                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    submitDebit = (e) => {
        e.preventDefault();
        let data = {
            added_by: CommonConfig.loginData().contact_no,
            created_on: this.state.created_on ? moment(this.state.created_on).format('YYYY-MM-DD') : '',
            requested_to: CommonConfig.isObjectEmpty(this.state.requested_to) ? '' : this.state.requested_to.value,
            debit_amount: this.state.debit_amount,
            description: this.state.description,
            TransactionProductDetailArr: this.state.TransactionProductDetailArr,
            DebitArr: this.state.creditDebitArr,
        }
        try {
            api.post("debit/AddAndEditDebitEntry", data).then(res => {
                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                    // this.setState(initialState);
                    this.setState({ TransactionProductDetailArr: [] })
                    this.setState({
                        creditDebitArr: [], description: '', requested_to: '', debit_amount: '',
                        edited_amount_added: '', edited_amount_requested: '', created_on: new Date(), edited_amount_added: '', showDebitModal: false
                    })
                    this.getCreditEntry();
                    this.getDebitEntry();
                    this.getLedgerList();
                    this.getProductList();
                    this.getTaxList();
                    this.getSummary();

                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    submitCredit = (e) => {
        e.preventDefault();
        let data = {
            added_by: CommonConfig.loginData().contact_no,
            created_on: this.state.created_on ? moment(this.state.created_on).format('YYYY-MM-DD') : '',
            requested_to: CommonConfig.isObjectEmpty(this.state.requested_to) ? '' : this.state.requested_to.value,
            credit_amount: this.state.credit_amount,
            description: this.state.description,
            TransactionProductDetailArr: this.state.TransactionProductDetailArr,
            CreditArr: this.state.creditDebitArr,
        }
        try {
            api.post("credit/AddAndEditCreditEntry", data).then(res => {
                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                    // this.setState(initialState);
                    this.setState({
                        TransactionProductDetailArr: [],
                        creditDebitArr: [], description: '', credit_amount: '', requested_to: '',
                        created_on: new Date(), edited_amount_added: '', edited_amount_requested: '',
                        edit_description: '', edit_amount: '', edit_created_on: '', showCreditModal: false
                    })
                    this.getCreditEntry();
                    this.getDebitEntry();
                    this.getLedgerList();
                    this.getProductList();
                    this.getTaxList();
                    this.getSummary();
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    UpdateTransactionDetail = (e) => {
        e.preventDefault();
        let data = {
            added_by: CommonConfig.loginData().contact_no,
            TransactionID: this.state.editTransactionID,
            edit_created_on: moment(this.state.edit_created_on).format('YYYY-MM-DD'),
            edit_amount: this.state.edit_amount,
            edit_description: this.state.edit_description,
            TransactionProductDetailArr: this.state.TransactionProductDetailArr,
            CreditDebitArr: this.state.creditDebitArr,
            IsType: this.state.isType == 1 ? 'Credit' : 'Debit'
        }
        var LedgerID = this.state.LedgerID
        try {
            api.post("statement/UpdateTransactionDetail", data).then(res => {
                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                    this.setState(initialState);
                    this.setState({ LedgerID: LedgerID })
                    this.getLedgerStatement(LedgerID)
                    this.getCreditEntry();
                    this.getDebitEntry();
                    this.getLedgerList();
                    this.getProductList();
                    this.getTaxList();
                    this.getSummary();
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    handleChangeCredit = (e, type) => {

        if (type == "description") {
            this.setState({ description: e.target.value })
        }
        else if (type == "requested_to") {
            this.setState({ requested_to: e })
            this.refInput.focus();
            let dateArray = [];
            for (let i = 0; i < this.state.CreditEntry.length; i++) {
                dateArray.push(this.state.CreditEntry[i].updated_on);
            }
            dateArray.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
            if (dateArray.length) {
                this.setState({ created_on: dateArray[0] })
            }
        }
        else if (type == "credit_amount") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ credit_amount: e.target.value })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                this.setState({ credit_amount: this.state.credit_amount })
            }
            else {
                this.setState({ credit_amount: e.target.value })
            }
        }
        else if (type == "created_on") {
            this.setState({ created_on: new Date(e) })
        }
        else if (type == "edited_amount_added") {
            this.setState({ edited_amount_added: e.target.value })
        }
        else if (type == "edited_amount_requested") {
            this.setState({ edited_amount_requested: e.target.value })
        }
        else if (type == "edit_description") {
            this.setState({ edit_description: e.target.value })
        }
        else if (type == "edit_amount") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ edit_amount: e.target.value })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                this.setState({ edit_amount: this.state.edit_amount })
            }
            else {
                this.setState({ edit_amount: e.target.value })
            }
        }
        else if (type == "edit_created_on") {
            this.setState({ edit_created_on: new Date(e) })
        }

        else if (type == "Amount") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ newAmountAdd: e.target.value })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                this.setState({ newAmountAdd: this.state.newAmountAdd })
            }
            else {
                this.setState({ newAmountAdd: e.target.value })
            }
        }
        else if (type == "newRemark") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ newRemark: e.target.value })
            }
            else {
                this.setState({ newRemark: e.target.value })
            }
        }
    }

    handleChangeDebit = (e, type) => {
        if (type == "description") {
            this.setState({ description: e.target.value })
        }
        else if (type == "requested_to") {
            this.setState({ requested_to: e })
            this.refInput.focus();
            let dateArray = [];
            for (let i = 0; i < this.state.DebitEntry.length; i++) {
                dateArray.push(this.state.DebitEntry[i].updated_on);
            }
            dateArray.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
            if (dateArray.length) {
                this.setState({ created_on: dateArray[0] })
            }
        }
        else if (type == "debit_amount") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ debit_amount: e.target.value })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                this.setState({ debit_amount: this.state.debit_amount })
            }
            else {
                this.setState({ debit_amount: e.target.value })
            }
        }
        else if (type == "edited_amount_added") {
            this.setState({ edited_amount_added: e.target.value })
        }
        else if (type == "edited_amount_requested") {
            this.setState({ edited_amount_requested: e.target.value })
        }
        else if (type == "created_on") {
            this.setState({ created_on: new Date(e) })
        }

    }

    getLedgerList = () => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
        }
        try {
            api.post("ledger/ViewAndLedger", data).then(res => {
                if (res.success) {
                    this.setState({
                        LedgerList: res.data,
                        // ledgerID: res.data.ledger_id
                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getLedgerStatement = (LedgerID) => {
        this.setState({ showLedgerModal: true })
        let data = {
            UserID: CommonConfig.loginData().contact_no,
            LedgerID: LedgerID,
            pdescription: this.state.Description,
            StartDate: CommonConfig.isEmpty(this.state.StartDate) ? '' : moment(this.state.StartDate).format('YYYY-MM-DD'),
            EndDate: CommonConfig.isEmpty(this.state.EndDate) ? '' : moment(this.state.EndDate).format('YYYY-MM-DD'),
            Other: this.state.Other
        }
        try {
            api.post("credit/getCreditDebitStatement", data).then(res => {
                if (res.success) {
                    console.log(" res.data", res.data)
                    this.setState({
                        LedgerStatementArr: res.data,

                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getLedgerFilterStatement = (LedgerID) => {
        this.setState({ showLedgerModal: true })
        let data = {
            UserID: CommonConfig.loginData().contact_no,
            LedgerID: LedgerID,
            pdescription: this.state.Description,
            StartDate: CommonConfig.isEmpty(this.state.StartDate) ? '' : moment(this.state.StartDate).format('YYYY-MM-DD'),
            EndDate: CommonConfig.isEmpty(this.state.EndDate) ? '' : moment(this.state.EndDate).format('YYYY-MM-DD'),
            Other: this.state.Other
        }
        try {
            api.post("credit/getCreditDebitStatement", data).then(res => {
                if (res.success) {

                    this.setState({
                        LedgerStatementArr: res.data,

                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getProductStatementByTransactionID = (TransactionID) => {
        this.setState({ showProductModal: true })
        let data = {
            TransactionID: TransactionID,
        }
        try {
            api.post("product/getProductByTransactionID", data).then(res => {
                if (res.success) {

                    this.setState({
                        ProductStatementArr: res.data,

                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }
    GetCreditDebitListByID = (TransactionID) => {
        this.setState({ showCreditDebitModal: true })
        let data = {
            TransactionID: TransactionID,
        }
        try {
            api.post("Ledger/GetCreditDebitListByID", data).then(res => {
                if (res.success) {

                    this.setState({
                        creditDebitList: res.data,

                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getCreditStatement = (LedgerID, Index) => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
            LedgerID: LedgerID,
            pdescription: '',
            StartDate: '',
            EndDate: ''
        }
        try {
            api.post("credit/getCreditDebitStatement", data).then(res => {
                if (res.success) {
                    var tempArr = this.state.CreditEntry;
                    tempArr[Index]['IsCreditDetialedEntrySelected'] = true;
                    tempArr[Index]['creditDetailedEntryArr'] = res.data;
                    this.setState({ CreditEntry: tempArr })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getDebitStatement = (LedgerID, Index) => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
            LedgerID: LedgerID,
            pdescription: '',
            StartDate: '',
            EndDate: ''
        }
        try {
            api.post("credit/getCreditDebitStatement", data).then(res => {
                if (res.success) {
                    var tempArr = this.state.DebitEntry;
                    tempArr[Index]['IsDebitDetailsEntrySelected'] = true;
                    tempArr[Index]['debitDetailedEntryArr'] = res.data;
                    this.setState({ DebitEntry: tempArr })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getCreditEntry = () => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
            PartyID: CommonConfig.isObjectEmpty(this.state.PartyID) ? '' : this.state.PartyID.value,
            StartDate: CommonConfig.isEmpty(this.state.StartDate) ? '' : moment(this.state.StartDate).format('YYYY-MM-DD'),
            EndDate: CommonConfig.isEmpty(this.state.EndDate) ? '' : moment(this.state.EndDate).format('YYYY-MM-DD'),
            Other: this.state.Other,
            // OpeningBalance: this.state.TaxSearchStringFrom,
            // ClosingBalance: this.state.TaxSearchStringTo

            // TaxSearchStringTo: '', TaxSearchStringFrom: ''
        }
        try {
            api.post("credit/getCreditEntry", data).then(res => {
                if (res.success) {

                    this.setState({
                        CreditEntry: res.data.map(x => ({
                            ...x,
                            IsCreditDetialedEntrySelected: false,
                            isDeleteSelected: false,
                            creditDetailedEntryArr: []
                        }))
                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getDebitEntry = () => {

        let data = {
            UserID: CommonConfig.loginData().contact_no,
            PartyID: CommonConfig.isObjectEmpty(this.state.PartyID) ? '' : this.state.PartyID.value,
            StartDate: CommonConfig.isEmpty(this.state.StartDate) ? '' : moment(this.state.StartDate).format('YYYY-MM-DD'),
            EndDate: CommonConfig.isEmpty(this.state.EndDate) ? '' : moment(this.state.EndDate).format('YYYY-MM-DD'),
            Other: this.state.Other,

        }
        try {
            api.post("debit/getDebitEntry", data).then(res => {
                if (res.success) {
                    this.setState({
                        DebitEntry: res.data
                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getEntryDetailByTransactionID = (TransactionID, LedgerID) => {
        this.setState({ showEditCreditModal: true, editTransactionID: TransactionID, LedgerID: LedgerID })
        let data = {
            UserID: CommonConfig.loginData().contact_no,
            TransactionID: TransactionID
        }
        try {
            api.post("statement/getEntryDetailsByID", data).then(res => {
                if (res.success) {
                    let arrData = [];
                    arrData.push(res.data)
                    let creditArrData = []
                    for (let i = 0; i < res.data[0].length; i++) {
                        if (res.data[0][i].newAmountAdd != null || res.data[0][i].newRemark != null) {
                            creditArrData.push({
                                newAmountAdd: res.data[0][i].newAmountAdd,
                                newRemark: res.data[0][i].newRemark,
                                amountType: res.data[0][i].amountType
                            })
                        }
                    }
                    this.setState({
                        edit_created_on: res.data[0][0].created_on ? new Date(res.data[0][0].created_on) : '',
                        edit_amount: res.data[0][0].amount,
                        edit_description: res.data[0][0].description,
                        edit_status: res.data[0][0].status,
                        TransactionProductDetailArr: res.data[1],
                        isAmountAdd: res.data[0][0].Remark && res.data[0][0].Amount ? true : false,
                        isType: res.data[0][0].isType,
                        creditDebitArr: creditArrData
                    })

                }
            }).catch(err => {
                console.log(err)
            })
        }
        catch (err) {
            console.log(err);
        }
    }

    handleOpen = (type) => {
        if (type === "Credit") {
            setTimeout(() => {
                this.innerRef.focus();
            }, 1)
            this.setState({ showCreditModal: true })
        }
        else if (type === "Debit") {
            setTimeout(() => {
                this.innerRef.focus();
            }, 1)
            this.setState({ showDebitModal: true })
        } else if (type === "EditDebit") {
            this.setState({ showEditDebitModal: true })
        }
        // else if (type === "EditCredit") {
        //     this.setState({ showEditCreditModal: true })
        // }



    }

    handleClose = (type) => {

        if (type === "Debit") {
            this.setState({
                description: '', requested_to: '', debit_amount: '',
                edited_amount_added: '', edited_amount_requested: '', created_on: new Date(), edited_amount_added: '',
                showDebitModal: false,
                creditDebitArr: []
            })
        }
        else if (type === "Credit") {
            this.setState({
                showCreditModal: false, isAmountAdd: false, creditDebitArr: [],
                description: '', credit_amount: '', requested_to: '',
                created_on: new Date(), edited_amount_added: '', edited_amount_requested: '',
                edit_description: '', edit_amount: '', edit_created_on: '',
            })
        } else if (type === "EditCredit") {
            this.setState({ showEditCreditModal: false, TransactionProductDetailArr: [] })
        } else if (type === "EditDebit") {
            this.setState({ showEditDebitModal: false, TransactionProductDetailArr: [] })
        }
        else if (type === "Ledger") {
            this.setState({ showLedgerAddModal: false, })
        }

    }

    handleOpenLedger = (type) => {

        if (type === "Ledger") {
            this.setState({ showLedgerModal: true })
        }
    }

    handleCloseLedger = (type) => {

        if (type === "Ledger") {
            this.setState({ showLedgerModal: false })
            this.props.history.push('/dashboard')
        }
    }

    handleOpenProduct = (type) => {

        if (type === "Product") {
            this.setState({ showProductModal: true })
        }
    }

    handleCloseProduct = (type) => {
        if (type === "Product") {
            this.setState({ showProductModal: false })
        }
        if (type === "CreditDebitModal") {
            this.setState({ showCreditDebitModal: false })
        }
    }

    handleChange = (e, type) => {
        if (type === "ProductName") {
            this.setState({ ProductName: e.target.value })
        }
        if (type === "Rate") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Rate: e.target.value })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                this.setState({ Rate: this.state.Rate })
            }
            else {
                this.setState({ Rate: e.target.value })
            }
        }
        if (type === "Quantity") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Quantity: e.target.value })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                this.setState({ Quantity: this.state.Quantity })
            }
            else {
                this.setState({ Quantity: e.target.value })
            }
        }
        if (type === "Remark") {
            this.setState({ Remark: e.target.value })
        }
        if (type == "PartyName") {
            this.setState({ PartyName: e.target.value })
        }
        if (type == "ContactNo") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ ContactNo: e.target.value })
            }
            else if (!e.target.value.match(CommonConfig.RegExp.number)) {
                this.setState({ ContactNo: this.state.ContactNo })
            }
            else {
                this.setState({ ContactNo: e.target.value })
            }
        }
    }
    handleLedger = async (e, ptype) => {
        let data = {
            UserID: CommonConfig.loginData().contact_no,
            partyName: this.state.PartyName,
            contactNo: this.state.ContactNo,
            ptype: ptype,
        }
        try {
            await api.post("ledger/AddEditDeleteLedger", data).then(res => {
                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                    if (res.data[0].vMessageType == "success") {
                        this.setState({
                            showLedgerAddModal: false,
                            PartyName: '', ContactNo: '',

                        });
                        this.getLedgerList();
                    }
                    else {
                        this.setState({
                            showLedgerAddModal: false,
                            PartyName: '', ContactNo: '',

                        });
                    }
                }
            }).catch(err => { })
        }
        catch (err) { }
    }
    submitLedger = async (e, ptype) => {

        e.preventDefault();
        if (ptype != 'Delete') {

            if (CommonConfig.isEmpty(this.state.PartyName) && CommonConfig.isEmpty(this.state.ContactNo)) {

                CommonConfig.showMessage('All field are required', 'error');
            }
            else {
                await this.handleLedger(e, ptype);
            }
        }
        else {
            await this.handleLedger(e, ptype);
        }
    }
    addTransactionProductDetail = () => {
        let newArr = [];
        newArr = this.state.TransactionProductDetailArr;
        var SubTotal = Number(this.state.Quantity) * Number(this.state.Rate);
        var Amount = 0;
        var sum = 0;
        let TotalCredit = 0;
        for (let i = 0; i < this.state.TaxArr.length; i++) {
            Amount = Amount + Number(this.state.TaxArr[i].Amount)
        }
        var Total = Amount + SubTotal;
        newArr.push({
            ProductName: this.state.ProductName,
            Quantity: this.state.Quantity,
            Rate: this.state.Rate,
            SubTotal: parseFloat(SubTotal).toFixed(2),
            Remark: this.state.Remark,
            Tax: this.state.TaxArr.length ? this.state.TaxArr.map(x => { return x.TaxName }).join() : "-",
            Value: this.state.TaxArr.length ? this.state.TaxArr.map(x => { return x.Value }).join() : 0,
            Amount: Amount,
            Total: parseFloat(Total).toFixed(2)
        })

        for (let i = 0; i < newArr.length; i++) {
            sum = sum + Number(newArr[i].Total)
        }

        // if (newArr.length >= 2) {
        //     for (let i = 0; i < newArr.length; i++) {

        //         TotalCredit = TotalCredit + Number(newArr[i].Total);
        //     }

        //     let prevBal = (this.state.edit_amount - (Number(newArr[newArr.length - 1].Total)))

        //     TotalCredit = TotalCredit + prevBal;
        //     this.setState({ edit_amount: '' })
        // } else {
        //     for (let i = 0; i < newArr.length; i++) {
        //         TotalCredit = TotalCredit + Number(newArr[i].Total)

        //     }
        //     TotalCredit = TotalCredit + this.state.edit_amount;
        // }
        // console.log("first", newArr, TotalCredit)

        this.setState({
            credit_amount: parseFloat(sum).toFixed(2),
            debit_amount: parseFloat(sum).toFixed(2),
            TransactionProductDetailArr: newArr,
            ProductName: '',
            Quantity: '',
            Rate: '',
            Remark: '',
            TaxArr: [
                {
                    TaxID: '',
                    TaxName: '',
                    Value: '',
                    Amount: ''
                }
            ],
            IsTaxSelected: false,
            // edit_amount: TotalCredit

        })
    }


    handleFormChange = (idx, e, type) => {
        let tempList = this.state.creditDebitArr;
        if (type === 'Amount') {
            if ((e.target.value).charAt(0) == ".") {
                e.target.value = "0" + e.target.value;
            }
            if (CommonConfig.isEmpty(e.target.value)) {
                let currentMainAmount = this.state.showCreditModal ? this.state.credit_amount : this.state.showEditCreditModal ? this.state.edit_amount : this.state.debit_amount;
                let newAmount = 0;
                if (tempList[idx]['amountType'] == "Addition") {
                    if (!isNaN(Number(tempList[idx]['newAmountAdd'])) && (e.target.value).charAt(0) != ".") {
                        currentMainAmount -= tempList[idx]['newAmountAdd'] ? parseFloat(tempList[idx]['newAmountAdd']) : 0;
                    }
                    newAmount = parseFloat(currentMainAmount) + 0;
                }
                else {
                    if (!isNaN(Number(tempList[idx]['newAmountAdd'])) && (e.target.value).charAt(0) != ".") {
                        currentMainAmount += tempList[idx]['newAmountAdd'] ? parseFloat(tempList[idx]['newAmountAdd']) : 0;
                    }
                    newAmount = parseFloat(currentMainAmount) - 0;
                }
                tempList[idx][e.target.name] = e.target.value.trim();
                this.setState({ creditDebitArr: tempList });
                if (this.state.showCreditModal) {
                    this.setState({ credit_amount: parseFloat(newAmount) });
                }
                if (this.state.showDebitModal) {
                    this.setState({ debit_amount: parseFloat(newAmount) })
                }
                if (this.state.showEditCreditModal) {
                    this.setState({ edit_amount: parseFloat(newAmount) })
                }
            }
            else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                tempList[idx][e.target.name] = tempList[idx][e.target.name];
                this.setState({ creditDebitArr: tempList });
            }
            else {

                let currentMainAmount = this.state.showCreditModal ? this.state.credit_amount : this.state.showEditCreditModal ? this.state.edit_amount : this.state.debit_amount;
                let newAmount = 0;
                if (tempList[idx]['amountType'] == "Addition") {
                    if (!isNaN(Number(tempList[idx]['newAmountAdd'])) && (e.target.value).charAt(0) != ".") {
                        currentMainAmount -= tempList[idx]['newAmountAdd'] ? parseFloat(tempList[idx]['newAmountAdd']) : 0;
                    }
                    newAmount = parseFloat(currentMainAmount) + (e.target.value && (e.target.value).charAt(0) != "." ? Number(parseFloat(e.target.value.trim()).toFixed(2)) : 0);
                }
                else {
                    if (!isNaN(Number(tempList[idx]['newAmountAdd'])) && (e.target.value).charAt(0) != ".") {
                        currentMainAmount += tempList[idx]['newAmountAdd'] ? parseFloat(tempList[idx]['newAmountAdd']) : 0;
                    }
                    newAmount = parseFloat(currentMainAmount) - (e.target.value && (e.target.value).charAt(0) != "." ? Number(parseFloat(e.target.value.trim()).toFixed(2)) : 0);
                }
                tempList[idx][e.target.name] = e.target.value.trim();
                this.setState({ creditDebitArr: tempList });
                if (this.state.showCreditModal) {
                    this.setState({ credit_amount: parseFloat(newAmount) });
                }
                if (this.state.showDebitModal) {
                    this.setState({ debit_amount: parseFloat(newAmount) })
                }
                if (this.state.showEditCreditModal) {
                    this.setState({ edit_amount: parseFloat(newAmount) })
                }
            }
        }
        else {
            let tempList = this.state.creditDebitArr;
            tempList[idx][e.target.name] = e.target.value;

            // let amount = Number(e.target.value.trim());
            // let newAmount = Number(this.state.credit_amount) + Number(e.target.value);
            // tempList[idx]['newAmountAdd'] = newAmount;
            this.setState({ creditDebitArr: tempList });
            // console.log("object", typeof (newAmount), newAmount);
            // this.setState({ credit_amount: Number(newAmount) });
        }


    };

    addField = (type) => {
        let getList = this.state.creditDebitArr;
        getList.push({
            newAmountAdd: '',
            newRemark: '',
            amountType: type
        })
        this.setState({ creditDebitArr: getList })
    }

    removeFields = (idx) => {
        let data = [...this.state.creditDebitArr];
        let currentMainAmount = this.state.showCreditModal ? this.state.credit_amount : this.state.showEditCreditModal ? this.state.edit_amount : this.state.debit_amount;
        let newAmount = 0;
        if (data[idx]['amountType'] == "Addition") {
            currentMainAmount -= data[idx]['newAmountAdd'] ? parseFloat(data[idx]['newAmountAdd']) : 0;
            newAmount = parseFloat(currentMainAmount);
        }
        else {
            currentMainAmount += data[idx]['newAmountAdd'] ? parseFloat(data[idx]['newAmountAdd']) : 0;
            newAmount = parseFloat(currentMainAmount);
        }
        if (this.state.showCreditModal) {
            this.setState({ credit_amount: parseFloat(newAmount) });
        }
        if (this.state.showDebitModal) {
            this.setState({ debit_amount: parseFloat(newAmount) })
        }
        if (this.state.showEditCreditModal) {
            this.setState({ edit_amount: parseFloat(newAmount) })
        }
        data.splice(idx, 1)
        this.setState({ creditDebitArr: data })
    }

    getUserMenu = async () => {
        try {
            let data = {
                UserID: CommonConfig.loginData().contact_no
            }
            await api.post("useraccess/getUserMenu", data)
                .then(async (res) => {
                    let response = await res;
                    if (response.success) {
                        this.setState({
                            ledgerAccess: response.data[5],
                            creditAccess: response.data[2],
                            debitAccess: response.data[3],
                            productAccess: response.data[6],
                            taxAccess: response.data[7],
                            userAccess: response.data[1],
                            importAccess: response.data[0]

                        })

                    } else {

                    }
                })
                .catch((err) => {

                })

        } catch (err) {

        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'y') {
            alert('The sky is your starting point!')
        }

    }
    printCreditStatement = async (data) => {
        console.log("first", data)
        this.setState({ creditData: data })
        let content = document.getElementById('credit-statement');
        const doc = new jsPDF.jsPDF('portrait', 'pt', 'a4');
        doc.setFont('Arial', 'Normal', '400');
        const div = content;
        await doc.html(div);
        doc.setProperties({
            title: 'Token',
            subject: 'Info about PDF',
            author: 'PDFAuthor',
            keywords: 'generated, javascript, web 2.0, ajax',
            creator: 'My Company'
        })
        // doc.save('test.pdf'); // save / download
        doc.output('dataurlnewwindow', { filename: 'Statement.pdf' });
    }
    handleClearBalance = () => {

        this.setState({ TaxSearchStringTo: '', TaxSearchStringFrom: '' })
        this.getCreditEntry();
        this.getDebitEntry();
    }
    handleBalance = () => {
        debugger
        if (this.state.TaxSearchStringTo && this.state.TaxSearchStringFrom) {
            let CreditEntry = this.state.CreditEntry.filter(x => x.BalArray[0].ClosingBal <= this.state.TaxSearchStringTo
                && x.BalArray[0].ClosingBal >= this.state.TaxSearchStringFrom)
            // console.log("credit entry", CreditEntry)
            this.setState({ CreditEntry: CreditEntry })
            let DebitEntry = this.state.DebitEntry.filter(x => x.BalArray[0].ClosingBal <= this.state.TaxSearchStringTo
                && x.BalArray[0].ClosingBal >= this.state.TaxSearchStringFrom)
            // console.log("credit entry", DebitEntry)
            this.setState({ DebitEntry: DebitEntry })
        }
        else {
            this.setState({ TaxSearchStringTo: '', TaxSearchStringFrom: '' })
            this.getCreditEntry();
            this.getDebitEntry();
        }
    }
    render() {
        const { importAccess, ledgerAccess, creditAccess, debitAccess, productAccess, taxAccess,
            userAccess, Menus, PartyName, ContactNo, Other, showCreditModal, showLedgerModal, CreditEntry, DebitEntry, ProductList, TaxtList, showEditCreditModal, LedgerList, added_by, created_on, requested_to, credit_amount, debit_amount, description, showDebitModal, status, edited_amount_added, edited_amount_requested, IsCreditDetailedEntry, CreditDebitStatement, LedgerStatementArr, LedgerStatement, ledger_username, showProductModal, ProductStatementArr, TransactionProductDetailArr, ProductName, Amount, Remark, TaxArr, Rate, Quantity, IsTaxSelected, IsProductSelected, edit_amount, edit_created_on, edit_description, IsOverallDeleteSelected, IsOverallDeleteSelected1, DebitCurrentTotal, CreditCurrentTotal, summaryDetails, isAmountAdd, amountType, newAmountAdd, newRemark, creditDebitArr, showCreditDebitModal, creditDebitList, showLedgerAddModal, TaxSearchStringTo, TaxSearchStringFrom, creditData } = this.state;


        if (this.props.location.state && this.props.location.state.LedgerID) {
            this.getLedgerStatement(this.props.location.state.LedgerID)
            this.props.history.push({
                state: undefined
            })
        }

        if (this.props.location.state && this.props.location.state.IsDashboardSelected) {
            this.props.history.push({
                state: undefined
            })
            this.setState(initialState)
            this.getCreditEntry();
            this.getDebitEntry();
            this.getLedgerList();
            this.getProductList();
            this.getTaxList();
            this.getSummary();
        }

        // window.onkeydown = (event) => {
        //     if (event.which === 67) {
        //         // write your logic here.
        //         this.handleOpen('Credit')
        //     }
        // }

        return (
            <div className="dashboard-container">

                {/* Filter section   */}
                <div className="card mb-2">
                    <div className="card-text px-0">
                        <div className="container-fluid">
                            <div className="row mx-0 d-flex justify-content-center">
                                {/* Under Development */}
                                <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-1 d-flex px-0">
                                    <div className="row w-100 mx-1">
                                        <div className="col-6 col-sm-12 col-md-12 col-lg-12 px-0 px-lg-1 d-flex align-self-center justify-content-center">
                                            <Chart
                                                width={'100px'}
                                                height={'80px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Transaction', 'Percentage'],
                                                    ['Credit', CommonConfig.isObjectEmpty(summaryDetails) ? 0 : parseInt(Math.abs(summaryDetails.CreditCurrentTotal))],
                                                    ['Debit', CommonConfig.isObjectEmpty(summaryDetails) ? 0 : parseInt(Math.abs(summaryDetails.OpeningBalance))],
                                                ]}
                                                options={{
                                                    pieHole: 0.4,
                                                    legend: 'none',
                                                    tooltip: { trigger: 'none' },
                                                    slices: {
                                                        0: { color: '#01c851' },
                                                        1: { color: '#e83737' },
                                                    },
                                                }}
                                                rootProps={{ 'data-testid': '2' }}
                                            />

                                        </div>
                                        <div className="col-6 col-sm-12 col-md-12 col-lg-12 col-xl-6 px-0 px-lg-1 my-2 mt-sm-0 display-lg-none">
                                            <div className="card-header-label main-total-number" title="Opening Balance">
                                                <div className="border-bottom">
                                                    <div className={!CommonConfig.isObjectEmpty(summaryDetails) && summaryDetails.OpeningBalance > 0 ? "credit-total mb-0" : "debit-total mb-0"}>
                                                        <div className="text-center">Opening Balance</div>
                                                        <div className="text-center">
                                                            <b id="overall-opening-balance">
                                                                <i className="fas fa-rupee-sign"></i>&nbsp;
                                                                {CommonConfig.isObjectEmpty(summaryDetails) ? parseFloat(0.00).toFixed(2) : CommonConfig.getIndianFormatAmount(parseFloat(Math.abs(summaryDetails.OpeningBalance)).toFixed(2))}
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={!CommonConfig.isObjectEmpty(summaryDetails) && summaryDetails.ClosingBalance > 0 ? "credit-total mb-0 w-100" : "debit-total mb-0 w-100"}>
                                                        <div className="text-center">You Will {!CommonConfig.isObjectEmpty(summaryDetails) && summaryDetails.ClosingBalance > 0 ? "Receive" : "Pay"}</div>
                                                        <div className="text-center">
                                                            <b id="overall-total-balance">
                                                                <i className="fas fa-rupee-sign"></i>&nbsp;
                                                                {CommonConfig.isObjectEmpty(summaryDetails) ? parseFloat(0.00).toFixed(2) : CommonConfig.getIndianFormatAmount(parseFloat(Math.abs(summaryDetails.ClosingBalance)).toFixed(2))}
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8 col-md-8 col-lg-10 col-xl-11 px-0 align-self-center">
                                    <div className="container-fluid p-0">
                                        <div className="row align-self-center mx-1">
                                            <div className="px-0 px-lg-1 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-2 align-items-center display-lg-block my-1">
                                                <div className="card-header-label main-total-number" title="Total Balance">
                                                    <div className="border-bottom">
                                                        <div className={!CommonConfig.isObjectEmpty(summaryDetails) && summaryDetails.OpeningBalance > 0 ? "credit-total mb-0 text-center" : "debit-total mb-0 text-center"}>
                                                            Opening Balance<br /> &nbsp;
                                                            <b id="overall-opening-balance-desktop">
                                                                <i className="fas fa-rupee-sign"></i>
                                                                &nbsp;
                                                                {CommonConfig.isObjectEmpty(summaryDetails) ? parseFloat(0.00).toFixed(2) : CommonConfig.getIndianFormatAmount(parseFloat(Math.abs(summaryDetails.OpeningBalance)).toFixed(2))}
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={!CommonConfig.isObjectEmpty(summaryDetails) && summaryDetails.ClosingBalance > 0 ? "credit-total mb-0 w-100 text-center" : "debit-total mb-0 w-100 text-center"}>
                                                            <div className="text-center">You Will {!CommonConfig.isObjectEmpty(summaryDetails) && summaryDetails.ClosingBalance > 0 ? "Receive" : "Pay"}</div>
                                                            <div className="text-center">
                                                                <b id="overall-total-balance-desktop"><i className="fas fa-rupee-sign"></i>&nbsp;{CommonConfig.isObjectEmpty(summaryDetails) ? parseFloat(0.00).toFixed(2) : CommonConfig.getIndianFormatAmount(parseFloat(Math.abs(summaryDetails.ClosingBalance)).toFixed(2))}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="display-sm-block px-0 px-lg-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 align-self-center my-1">
                                                <div className="card-element  mb-0">
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-group mb-0 w-100">
                                                            {/* <select className="form-control select2-hidden-accessible" id="filter_party_name" data-select2-id="filter_party_name" tabindex="-1" aria-hidden="true" value={this.state.PartyID} onChange={(e) => this.setState({ PartyID: e.target.value })}>
                                                                <option value="" data-select2-id="2">Select Party</option>
                                                                {LedgerList.map(x => {
                                                                    return (<option class="select-option" value={x.ledger_user_id}>
                                                                        {x.ledger_username} ({x.ledger_user_id})</option>
                                                                    )
                                                                })}
                                                            </select> */}
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                placeholder="Select Party"
                                                                value={this.state.PartyID}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(e) => this.setState({ PartyID: e })}
                                                                //   name="color"
                                                                options={LedgerList.length ? LedgerList.map(x => { return { value: x.ledger_user_id, label: x.ledger_username + "(" + x.ledger_user_id + ")", ledgerID: x.ledger_id } }) : []}
                                                            // components={{ Option: CustomOption }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="display-sm-block px-0 pr-md-1 col-12 col-sm-12 col-md-10 col-lg-10 col-xl-4 my-1 mt-3 mt-xl-1 align-self-center">
                                                <div className="card-element mb-0">
                                                    <div className="row mx-0 mx-lg-1">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 mb-1 mb-sm-0 px-0 pr-sm-1">
                                                            <div className="date-label">From</div>
                                                            {/* <input type="date" name="start_date" id="plan" placeholder="Start Date" onChange={(e) => this.setState({ StartDate: e.target.value })} value={this.state.StartDate ? moment(this.state.StartDate).format('YYYY-MM-DD') : ''} /> */}
                                                            {/* <DatePicker
                                                                onChange={(e) => this.setState({ StartDate: e })} value={this.state.StartDate}
                                                                format="dd-MM-y"
                                                                dayPlaceholder="dd"
                                                                monthPlaceholder="mm"
                                                                yearPlaceholder="yyyy"
                                                                showLeadingZeros={false}
                                                            /> */}
                                                            <DatePicker
                                                                animation
                                                                editable={false}
                                                                type="input-icon"
                                                                placeholder="Start Date"
                                                                format="DD/MM/YYYY"
                                                                value={this.state.StartDate}
                                                                onChange={(e) => this.setState({ StartDate: new Date(e) })}
                                                            />
                                                            {/* <div id="datepicker" className="input-group date datepicker_filter" data-date-format="dd-mm-yyyy">

                                                                <span className="input-group-addon">
                                                                    <i className="glyphicon glyphicon-calendar"></i>
                                                                </span>
                                                            </div> */}
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 mt-1 mt-sm-0 px-0 pl-sm-1">
                                                            <div className="date-label">To</div>
                                                            {/* <DatePicker
                                                                onChange={(e) => this.setState({ EndDate: e })} value={this.state.EndDate}
                                                                format="dd-MM-y"
                                                                dayPlaceholder="dd"
                                                                monthPlaceholder="mm"
                                                                yearPlaceholder="yyyy"
                                                                showLeadingZeros={false}
                                                            /> */}
                                                            <DatePicker
                                                                animation
                                                                editable={false}
                                                                type="input-icon"
                                                                placeholder="End Date"
                                                                format="DD/MM/YYYY"
                                                                value={this.state.EndDate}
                                                                onChange={(e) => this.setState({ EndDate: new Date(e) })}
                                                            />
                                                            {/* <div id="datepicker" className="input-group date datepicker_filter" data-date-format="dd-mm-yyyy">
                                                                <input type="date" name="end_date" id="plan1" placeholder="End Date" onChange={(e) => this.setState({ EndDate: e.target.value })} value={this.state.EndDate ? moment(this.state.EndDate).format('YYYY-MM-DD') : ''} />
                                                                <span className="input-group-addon">
                                                                    <i className="glyphicon glyphicon-calendar"></i>
                                                                </span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="display-sm-block align-self-center px-0 px-lg-1 pl-md-1 col-4 col-sm-4 col-md-2 col-lg-2 col-xl-1 my-1 mt-md-3 mt-lg-1">
                                                <div className="d-flex justify-content-center ">
                                                    <div className="align-self-center">
                                                        <button className="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0" onClick={() => {
                                                            this.getCreditEntry()
                                                            this.getDebitEntry()
                                                            this.getSummary()
                                                        }}>
                                                            <i className="fas fa-check"></i>
                                                        </button>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <button className="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset" onClick={() => {
                                                            {
                                                                // this.setState(initialState)
                                                                let data = {
                                                                    UserID: CommonConfig.loginData().contact_no,
                                                                    PartyID: "",
                                                                    StartDate: "",
                                                                    EndDate: "",
                                                                    Other: "",
                                                                }
                                                                try {
                                                                    api.post("credit/getCreditEntry", data).then(res => {
                                                                        if (res.success) {

                                                                            this.setState({
                                                                                CreditEntry: res.data
                                                                            })
                                                                        }
                                                                    }).catch(err => { })
                                                                }
                                                                catch (err) { }
                                                            }
                                                            {

                                                                let data = {
                                                                    UserID: CommonConfig.loginData().contact_no,
                                                                    PartyID: "",
                                                                    StartDate: "",
                                                                    EndDate: "",
                                                                    Other: "",
                                                                }
                                                                try {
                                                                    api.post("debit/getDebitEntry", data).then(res => {
                                                                        if (res.success) {

                                                                            this.setState({
                                                                                DebitEntry: res.data
                                                                            })
                                                                        }
                                                                    }).catch(err => { })
                                                                }
                                                                catch (err) { }
                                                            }
                                                            {

                                                                let data = {
                                                                    UserID: CommonConfig.loginData().contact_no,
                                                                    PartyID: '',
                                                                    StartDate: '',
                                                                    EndDate: '',
                                                                    Other: '',
                                                                }
                                                                try {
                                                                    api.post("filter/getDashboardSummaryDetails", data).then(res => {
                                                                        if (res.success) {

                                                                            this.setState({
                                                                                summaryDetails: res.data.length ? res.data[0] : {}
                                                                            })
                                                                        }
                                                                    }).catch(err => { })
                                                                }
                                                                catch (err) { }
                                                            }
                                                            this.getLedgerList();
                                                            this.getProductList();
                                                            this.getTaxList();

                                                            this.setState({ EndDate: '', StartDate: '', PartyID: '' })
                                                        }}>
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="display-sm-none px-0 pr-md-1 col-12 col-sm-12 col-md-10 col-lg-10 col-xl-4 my-1">
                                                <div className="card-element mb-0">
                                                    <div className="row mx-0 mx-lg-1">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 mb-2 mb-sm-0 px-0 pr-sm-1 mt-3">
                                                            <div className="date-label">From</div>
                                                            <input type="date" name="start_date" id="plan" placeholder="Start Date" onChange={(e) => this.setState({ StartDate: e.target.value })} value={this.state.StartDate ? moment(this.state.StartDate).format('YYYY-MM-DD') : ''} />
                                                            <div id="datepicker" className="input-group date datepicker_filter" data-date-format="dd-mm-yyyy">

                                                                <span className="input-group-addon">
                                                                    <i className="glyphicon glyphicon-calendar"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 px-0 pl-sm-1 mt-3">
                                                            <div className="date-label">To</div>
                                                            <div id="datepicker" className="input-group date datepicker_filter" data-date-format="dd-mm-yyyy">
                                                                <input type="date" name="end_date" id="plan1" placeholder="End Date" onChange={(e) => this.setState({ EndDate: e.target.value })} value={this.state.EndDate ? moment(this.state.EndDate).format('YYYY-MM-DD') : ''} />
                                                                <span className="input-group-addon">
                                                                    <i className="glyphicon glyphicon-calendar"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-sm-none px-0 px-lg-1 col-8 col-sm-8 col-md-10 col-lg-7 col-xl-2 align-self-center my-1">
                                                <div className="card-element mb-0">
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-group mb-0 w-100">
                                                            <select value={this.state.PartyID} onChange={(e) => this.setState({ PartyID: e.target.value })} className="form-control" id="filter_party_name_res">
                                                                <option value="">Select Party</option>

                                                                {LedgerList.map(x => {
                                                                    return (<option class="select-option" value={x.ledger_user_id}>
                                                                        {x.ledger_username} ({x.ledger_user_id})</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-sm-none px-0 px-lg-1 pl-md-1 col-4 col-sm-4 col-md-2 col-lg-2 col-xl-1 my-1 mt-md-3 mt-lg-1">
                                                <div className="d-flex justify-content-center ">
                                                    <div className="align-self-center">
                                                        <button className="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0" onClick={() => {
                                                            this.getCreditEntry()
                                                            this.getDebitEntry()
                                                        }}>
                                                            <i className="fas fa-check"></i>
                                                        </button>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <button className="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset" onClick={() => {
                                                            this.setState(initialState);
                                                            this.setState({ EndDate: '', StartDate: '', PartyID: '' })
                                                        }}>
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="px-0 px-lg-1 btn-filter col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 align-self-center my-1">
                                                <div className="btn-group w-100 header-icon" role="group" aria-label="Basic example">
                                                    <button type="button" className={Other === "Day" ? "btn btn-hover-effect-active" : 'btn btn-hover-effect'} onClick={() => {
                                                        this.getCreditEntry()
                                                        this.getDebitEntry()
                                                        this.setState({ Other: 'Day' })

                                                    }}>Day</button>
                                                    <button type="button" className={Other === "Week" ? "btn btn-hover-effect-active" : 'btn btn-hover-effect'} onClick={() => {
                                                        this.getCreditEntry()
                                                        this.getDebitEntry()
                                                        this.setState({ Other: 'Week' })

                                                    }}>Week</button>
                                                    <button type="button" className={Other === "Month" ? "btn btn-hover-effect-active" : 'btn btn-hover-effect'} onClick={() => {
                                                        this.getCreditEntry()
                                                        this.getDebitEntry()
                                                        this.setState({ Other: 'Month' })

                                                    }}>Month</button>
                                                    <button type="button" className={Other === "Year" ? "btn btn-hover-effect-active" : 'btn btn-hover-effect'} onClick={() => {
                                                        this.getCreditEntry()
                                                        this.getDebitEntry()
                                                        this.setState({ Other: 'Year' })

                                                    }}>Year</button>
                                                </div>
                                            </div> */}
                                            <div className="px-0 px-lg-1 btn-filter col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 align-self-center my-1">
                                                <div className="btn-group w-100 header-icon" role="group" aria-label="Basic example">
                                                    <div className="align-self-center mx-2">
                                                        <div className="card-element mb-0">
                                                            <input type="text" placeholder="Opening Balance From"
                                                                value={TaxSearchStringFrom} className="p-1" onChange={(e) => this.setState({ TaxSearchStringFrom: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="align-self-center mx-2">
                                                        <div className="card-element mb-0">
                                                            <input type="text" placeholder="Opening Balance To"
                                                                value={TaxSearchStringTo} className="p-1" onChange={(e) => this.setState({ TaxSearchStringTo: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <i className="fas fa-check" onClick={() => {
                                                            this.handleBalance()
                                                        }
                                                        }></i>

                                                        <i className="fas fa-times" onClick={
                                                            // this.setState({ TaxSearchStringTo: '', TaxSearchStringFrom: '' })
                                                            this.handleClearBalance
                                                        }></i>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Credit/Debit Section */}
                {creditAccess.MenuName == 'Credit' && creditAccess.ViewAccess == 1 ?
                    <div className="row">
                        {/* Credit  List Start*/}
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 pr-lg-1 mb-2 mb-lg-0">
                            <div className="card card-trans w-100">
                                <div className="card-header-panel position-relative d-flex align-items-center justify-content-between">
                                    <div className="d-flex">
                                        <div className="credit-sign align-self-center indicator"></div>
                                        <h5 className="card-title secondary-color align-self-center">Credit/ Receipt/ Purchase</h5>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="card-header-label p-2 ml-3" title="Total Balance: You are Owed">
                                            <span className="credit-total mb-0 text-center align-item-center d-flex">
                                                <img src={moneycoming} alt="moneycoming" width="20" height="20" className="mr-2" />
                                                <b className="text-nowrap" id="overall-credit">
                                                    <i className="fas fa-rupee-sign"></i>&nbsp;
                                                    {/* {CreditEntry.length ? CreditEntry[0].CreditCurrentTotal === 0 ? parseFloat(0).toFixed(2) : parseFloat(CreditEntry[0].CreditCurrentTotal).toFixed(2) : parseFloat(0).toFixed(2)} */}
                                                    {CommonConfig.getIndianFormatAmount(CommonConfig.isObjectEmpty(summaryDetails) ? parseFloat(0.00).toFixed(2) : parseFloat(Math.abs(summaryDetails.CreditCurrentTotal)).toFixed(2))}
                                                </b>
                                            </span>
                                        </div>
                                        {!IsOverallDeleteSelected ?


                                            creditAccess.DeleteAccess == 1 ?
                                                <>

                                                    <button className="card-header-btn" id="credit-click-button" onClick={(e) => this.setState({ IsOverallDeleteSelected: true })}>
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </> : <></>

                                            :
                                            <>
                                                <button className="card-header-btn" id="credit-cancel-button" onClick={(e) => this.setState({ IsOverallDeleteSelected: false })}>
                                                    <i className="fas fa-times"></i>
                                                </button>

                                                <div className="text-center" id="delete_credit">
                                                    <button type="button" className="Cancel-btn trash-button" onClick={(e) => this.DeleteCreditTransactionList()}>
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>

                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="card-text p-0 h-100">
                                        {CreditEntry.length ?
                                            CreditEntry.map((x, idx) => {
                                                return (
                                                    <div>
                                                        <div className="list-container list-container-credit">
                                                            {IsOverallDeleteSelected ?
                                                                <div className="list-checkbox p-0 mt-1">
                                                                    <input type="checkbox" className="checkbox-credit-checkout checkbox-checkout mr-2 d-block" checked={x.isDeleteSelected} onChange={(e) => {
                                                                        var tempArr = CreditEntry;
                                                                        CreditEntry[idx].isDeleteSelected = e.target.checked;
                                                                        this.setState({ CreditEntry: tempArr })
                                                                    }} />
                                                                </div>
                                                                : null}

                                                            <div className="list-details w-100">
                                                                <div className="d-flex justify-content-between">

                                                                    <div className="list-party-name">
                                                                        <label className="text-nowrap mr-2 mb-0" data-toggle="modal" data-target="#userStatement" onClick={() => this.getLedgerStatement(x.ledger_user_id)}>
                                                                            {x.ledger_username}</label>
                                                                        <span className="secondary-color text-nowrap"><i className="fas fa-phone-alt"></i> {x.ledger_user_id}</span>
                                                                    </div>
                                                                    <div className="list-credit-amount dashboard"><span className="text-nowrap"><i className="fas fa-rupee-sign"></i>&nbsp;{CommonConfig.getIndianFormatAmount(x.credit_amount)}</span></div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between mt-1">
                                                                    <div className="list-note text-left text-truncate w-100">
                                                                        {x.IsCreditDetialedEntrySelected ?
                                                                            <button className="bg-transparent border-0" id="button-credit-up-12" onClick={() => {
                                                                                var tempArr = CreditEntry;
                                                                                tempArr[idx]['IsCreditDetialedEntrySelected'] = false;
                                                                                this.setState({ CreditEntry: tempArr })
                                                                            }}>
                                                                                <i className="fas fa-chevron-down text-secondary" id="button-i"></i>
                                                                            </button>
                                                                            :
                                                                            <button className="bg-transparent border-0" id="button-credit-down-12" onClick={() => this.getCreditStatement(x.ledger_user_id, idx)}>
                                                                                <i className="fas fa-chevron-right text-secondary"></i>
                                                                            </button>
                                                                        }
                                                                        <span className="ml-2" title="Test">
                                                                            {x.description}</span>
                                                                    </div>
                                                                    <div className="text-right text-nowrap">
                                                                        <span className="List-date secondary-color"><span> {x.updated_on ? moment(x.updated_on).format('DD MMM YYYY') : ''}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-2" />
                                                                {x.IsCreditDetialedEntrySelected ?

                                                                    <div id="data-credit-12" style={{ display: "block" }}>
                                                                        {x.creditDetailedEntryArr.map((content, contentidx) => {
                                                                            return (
                                                                                <div>

                                                                                    {content.debit_amount === 0 ? null : <div>
                                                                                        {/* {contentidx === 0 ? null : <hr class="my-2" />} */}
                                                                                        <div>
                                                                                            <div class="d-flex flex-column flex-sm-row justify-content-between mt-1">
                                                                                                <div class="list-note text-left text-truncate w-100">
                                                                                                    <span>
                                                                                                        {content.description}</span>
                                                                                                    <span class="list-debit-amount">
                                                                                                        <i class="fas fa-rupee-sign">
                                                                                                        </i>
                                                                                                        {content.debit_amount}</span>
                                                                                                </div>
                                                                                                <div class="text-right text-nowrap">
                                                                                                    <span class="List-date secondary-color">
                                                                                                        <span> {content.created_on ? moment(content.created_on).format('DD MMM YYYY') : ''}</span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr class="my-2" />
                                                                                    </div>}
                                                                                    {content.credit_amount === 0 ? null : <div>
                                                                                        {/* {contentidx === 0 ? null : <hr class="my-2" />} */}
                                                                                        <div>
                                                                                            <div class="d-flex flex-column flex-sm-row justify-content-between mt-1">
                                                                                                <div class="list-note text-left text-truncate w-100">
                                                                                                    <span>
                                                                                                        {content.description}</span>
                                                                                                    <span class="list-credit-amount">
                                                                                                        <i class="fas fa-rupee-sign">
                                                                                                        </i>
                                                                                                        {content.credit_amount}</span>
                                                                                                </div>
                                                                                                <div class="text-right text-nowrap">
                                                                                                    <span class="List-date secondary-color">
                                                                                                        <span> {content.created_on ? moment(content.created_on).format('DD MMM YYYY') : ''}</span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr class="my-2" />
                                                                                    </div>}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                            :
                                            <div class="d-flex h-100">
                                                <div class="empty-img-user">
                                                    <img src={emptyCredit} alt="" />
                                                    <h4 class="empty-img-text py-3">You Owes No-one</h4>
                                                </div>

                                            </div>
                                        }

                                    </div>
                                    {
                                        creditAccess.MenuName == 'Credit' && creditAccess.AddAccess == 1 ?
                                            <div className="pluse-button add-credit-btn d-flex align-items-center justify-content-center" onClick={() => this.handleOpen('Credit')} >
                                                <i className="fas fa-plus add-btn-icon font-weight-bold"></i>
                                            </div>
                                            :
                                            null
                                    }
                                    {/* <div className="pluse-button add-credit-btn d-flex align-items-center justify-content-center" onClick={() => this.handleOpen('Credit')}>
                                    <i className="fas fa-plus add-btn-icon font-weight-bold"></i>
                                </div> */}
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-3 col-sm-2 col-md-2 col-lg-2 align-self-center">
                                            <div className="card-element mb-0">
                                                <div className="form-group mb-0">
                                                    <select className="form-control w-auto" name="state" id="paginationperpagecredit">
                                                        <option value="5">5</option>
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                        <option value="">All</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="align-self-center pr-2">
                                            {/* <div id="paginationcredit" className="light-theme simple-pagination">
                                            <ul><li className="disabled"><span className="current prev">Prev</span></li><li className="active"><span className="current">1</span></li><li className="disabled"><span className="current next">Next</span></li></ul></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Credit List end */}

                        {/* Debit List start */}
                        {debitAccess.MenuName == 'Debit' && debitAccess.ViewAccess == 1 ?
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 pl-lg-1">
                                <div className="card card-trans w-100">
                                    <div className="card-header-panel d-flex align-items-center justify-content-between position-relative">
                                        <div className="d-flex">
                                            <div className="debit-sign align-self-center indicator"></div>
                                            <h5 className="card-title secondary-color align-self-center">
                                                Debit/ Payment/ Sales</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="card-header-label p-2 ml-3" title="Total Balance: you own">
                                                <span className="debit-total mb-0 text-center align-item-center d-flex">
                                                    <img src={moneygoing} alt="moneygoing" width="20" height="20" className="mr-2" /> <b className="text-nowrap" id="overall-debit"><i className="fas fa-rupee-sign"></i>&nbsp;

                                                        {/* {CreditEntry.length ? CreditEntry[0].DebitCurrentTotal === 0 ? parseFloat(0).toFixed(2) : parseFloat(CreditEntry[0].DebitCurrentTotal).toFixed(2) : parseFloat(0).toFixed(2)} */}
                                                        {CommonConfig.getIndianFormatAmount(CommonConfig.isObjectEmpty(summaryDetails) ? parseFloat(0.00).toFixed(2) : parseFloat(Math.abs(summaryDetails.DebitCurrentTotal)).toFixed(2))}
                                                    </b>

                                                </span>
                                            </div>
                                            {!IsOverallDeleteSelected1 ?

                                                debitAccess.MenuName == 'Debit' && debitAccess.DeleteAccess == 1 ?
                                                    <>
                                                        <button className="card-header-btn" id="credit-click-button" onClick={(e) => this.setState({ IsOverallDeleteSelected1: true })}>
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </> : <></>
                                                :
                                                <>
                                                    <button className="card-header-btn" id="credit-cancel-button" onClick={(e) => this.setState({ IsOverallDeleteSelected1: false })}>
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                    <div className="text-center" id="delete_credit">
                                                        <button type="button" className="Cancel-btn trash-button" onClick={(e) => this.DeleteDebitTransactionList()}>
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-text p-0 h-100">

                                            {DebitEntry.length ?
                                                DebitEntry.map((x, idx) => {
                                                    return (
                                                        <div>
                                                            <div className="list-container list-container-credit">
                                                                {IsOverallDeleteSelected1 ?
                                                                    <div className="list-checkbox p-0 mt-1">
                                                                        <input type="checkbox" className="checkbox-credit-checkout checkbox-checkout mr-2 d-block" checked={x.isDeleteSelected} onChange={(e) => {
                                                                            var tempArr = DebitEntry;
                                                                            DebitEntry[idx].isDeleteSelected = e.target.checked;
                                                                            this.setState({ DebitEntry: tempArr })
                                                                        }} />
                                                                    </div>
                                                                    : null}
                                                                <div className="list-details w-100">
                                                                    <div className="d-flex justify-content-between">

                                                                        <div className="list-party-name">
                                                                            <label className="text-nowrap mr-2 mb-0" data-toggle="modal" data-target="#userStatement" onClick={() => this.getLedgerStatement(x.ledger_user_id)}>
                                                                                {x.ledger_username}</label>
                                                                            <span className="secondary-color text-nowrap"><i className="fas fa-phone-alt"></i> {x.ledger_user_id}</span>
                                                                        </div>
                                                                        <div className="list-debit-amount dashboard"><span className="text-nowrap"><i className="fas fa-rupee-sign"></i>&nbsp;{CommonConfig.getIndianFormatAmount(x.debit_amount)}</span></div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                                                        <div className="list-note text-left text-truncate w-100">
                                                                            {x.IsDebitDetailsEntrySelected ?
                                                                                <button className="bg-transparent border-0" id="button-credit-up-12" onClick={() => {
                                                                                    var tempArr = DebitEntry;
                                                                                    tempArr[idx]['IsDebitDetailsEntrySelected'] = false;
                                                                                    this.setState({ DebitEntry: tempArr })
                                                                                }}>
                                                                                    <i className="fas fa-chevron-down text-secondary" id="button-i"></i>
                                                                                </button>
                                                                                :
                                                                                <button className="bg-transparent border-0" id="button-credit-down-12" onClick={() => this.getDebitStatement(x.ledger_user_id, idx)}>
                                                                                    <i className="fas fa-chevron-right text-secondary"></i>
                                                                                </button>
                                                                            }
                                                                            <span className="ml-2" title="Test">
                                                                                {x.description}</span>
                                                                        </div>
                                                                        <div className="text-right text-nowrap">
                                                                            <span className="List-date secondary-color"><span> {x.updated_on ? moment(x.updated_on).format('DD MMM YYYY') : ''}</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-2" />
                                                                    {x.IsDebitDetailsEntrySelected ?

                                                                        <div id="data-credit-12">
                                                                            {x.debitDetailedEntryArr.map(content => {
                                                                                return (
                                                                                    <div>
                                                                                        {content.debit_amount === 0 ? null : <div>
                                                                                            <div>
                                                                                                <div class="d-flex flex-column flex-sm-row justify-content-between mt-1">
                                                                                                    <div class="list-note text-left text-truncate w-100">
                                                                                                        <span>
                                                                                                            {content.description}</span>
                                                                                                        <span class="list-debit-amount">
                                                                                                            <i class="fas fa-rupee-sign">
                                                                                                            </i>
                                                                                                            {content.debit_amount}</span>
                                                                                                    </div>
                                                                                                    <div class="text-right text-nowrap">
                                                                                                        <span class="List-date secondary-color">
                                                                                                            <span> {content.created_on ? moment(content.created_on).format('DD MMM YYYY') : ''}</span></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <hr class="my-2" />
                                                                                        </div>}
                                                                                        {content.credit_amount === 0 ? null : <div>
                                                                                            <div>
                                                                                                <div class="d-flex flex-column flex-sm-row justify-content-between mt-1">
                                                                                                    <div class="list-note text-left text-truncate w-100">
                                                                                                        <span>
                                                                                                            {content.description}</span>
                                                                                                        <span class="list-credit-amount">
                                                                                                            <i class="fas fa-rupee-sign">
                                                                                                            </i>
                                                                                                            {content.credit_amount}</span>
                                                                                                    </div>
                                                                                                    <div class="text-right text-nowrap">
                                                                                                        <span class="List-date secondary-color">
                                                                                                            <span> {content.created_on ? moment(content.created_on).format('DD MMM YYYY') : ''}</span></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <hr class="my-2" />
                                                                                        </div>}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                                :
                                                <div class="d-flex h-100">
                                                    <div class="empty-img-user">
                                                        <img src={emptyDebit} alt="" />
                                                        <h4 class="empty-img-text py-3">No-one Owes You</h4>
                                                    </div>

                                                </div>
                                            }

                                        </div>
                                        {
                                            debitAccess.MenuName == "Debit" && debitAccess.AddAccess == 1 ?
                                                <div className="pluse-button add-debit-btn d-flex align-items-center justify-content-center" onClick={() => this.handleOpen('Debit')}>
                                                    <i className="fas fa-plus add-btn-icon font-weight-bold"></i>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-3 col-sm-2 col-md-2 col-lg-2 align-self-center">
                                                <div className="card-element mb-0">
                                                    <div className="form-group mb-0">
                                                        <select className="form-control w-auto" name="state" id="paginationperpagedebit">
                                                            <option value="5">5</option>
                                                            <option value="10">10</option>
                                                            <option value="15">15</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            <option value="">All</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="align-self-center pr-2">
                                                {/* <div id="paginationdebit" className="light-theme simple-pagination"><ul><li className="disabled"><span className="current prev">Prev</span></li><li className="active"><span className="current">1</span></li><li className="disabled"><span className="current next">Next</span></li></ul></div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    : null}
                {/* Debit List end */}
                {/* Credit Modal start*/}
                <Modal show={showCreditModal} dialogClassName="full-screen-modal" className="full-screen-modal" onHide={() => this.handleClose("Credit")}>
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">Add Credit/ Receipt/ Purchase</h5>
                        <div class="d-flex">
                            <button type="button" class="align-self-center card-header-btn" onClick={() => this.handleClose("Credit")}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body user-modal pb-4 py-0">
                        <div class="add-party" id="addCreditParty">
                            <div class="party-close-button">
                                <i class="fas fa-times align-item-center"></i>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <label for="username">Date</label>
                                    </div>
                                    <div id="datepicker_entry" class="input-group date datepicker_entry" data-date-format="dd-mm-yyyy">
                                        {/* <DatePicker className="input-group date datepicker_entry"
                                        onChange={(e) => this.handleChangeCredit(e, "created_on")} value={created_on}
                                        format="dd-MM-y"
                                        dayPlaceholder="dd"
                                        monthPlaceholder="mm"
                                        yearPlaceholder="yyyy"
                                        showLeadingZeros={false}
                                    /> */}
                                        <DatePicker
                                            onBlur={(e) => e.stopPropogation()}
                                            animation
                                            editable={false}
                                            type="input-icon"
                                            placeholder="Date"
                                            format="DD/MM/YYYY"
                                            value={created_on}
                                            onChange={(e) => this.handleChangeCredit(e, "created_on")}
                                        />
                                        {/* <input type="date" id="credit_date" required value={created_on} onChange={(e) => this.handleChangeCredit(e, "created_on")} />
                                        <span class="input-group-addon">
                                            <i class="glyphicon glyphicon-calendar"></i>
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card-element ">
                                    <div class="pb-2">
                                        <label for="plan">Select Party Name</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="form-group w-100">
                                            {/* <select class="form-control select2-hidden-accessible" id="credit_party" data-select2-id="credit_party" tabindex="-1" aria-hidden="true" value={requested_to} onChange={(e) => this.handleChangeCredit(e, "requested_to")}>
                                                <option data-select2-id="4">Select Party</option>

                                                {LedgerList.map(x => {
                                                    return (<option class="select-option" value={x.ledger_user_id}>
                                                        {x.ledger_username} ({x.ledger_user_id})</option>
                                                    )
                                                })}


                                            </select> */}
                                            <Select
                                                ref={innerRef => this.innerRef = innerRef}
                                                autoFocus={true}
                                                className="select2-hidden-accessible"
                                                classNamePrefix="select"
                                                placeholder="Select Party"
                                                value={requested_to}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.handleChangeCredit(e, "requested_to")}
                                                //   name="color"
                                                options={LedgerList.length ? LedgerList.map(x => { return { value: x.ledger_user_id, label: x.ledger_username + "(" + x.ledger_user_id + ")" } }) : []}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Escape" || event.which === 27) {
                                                        // write your logic here.
                                                        this.setState({ showCreditModal: false })
                                                    }
                                                }}
                                            />

                                        </div>
                                        <div class="overflow-hidden mb-3">
                                            <button class="float-right card-header-product-btn w-auto px-2 align-self-center" onClick={() => this.setState({ showLedgerAddModal: true })}>
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <label for="contact">Contact No.</label>
                                    </div>
                                    <input type="text" disabled id="credit_contact" readonly value={CommonConfig.isObjectEmpty(requested_to) ? '' : requested_to.value} onChange={(e) => this.handleChangeCredit(e, "requested_to")} />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <div className="d-flex align-items-center">
                                            <label for="password" className='mr-2'>Amount</label>
                                            <div className="align-self-center">
                                                <button className="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0" onClick={() => this.addField("Addition")
                                                }
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset"
                                                    onClick={() => this.addField("Subtraction")}
                                                >
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" id="credit_amount" value={credit_amount}
                                        ref={(input) => { this.refInput = input; }} onChange={(e) => this.handleChangeCredit(e, "credit_amount")} />
                                </div>
                            </div>
                        </div>



                        {creditDebitArr.map((x, idx) => {
                            return (
                                <div className='row'>
                                    <div className='col-md-6'></div>
                                    <div className='col-md-6'>
                                        <div class="row align-items-center" key={idx}>
                                            <div className="col-md-1 mt-3">
                                                {x.amountType == 'Addition' ?
                                                    <button className="credit-sign text-white p-3 m-0 d-flex align-items-center justify-content-center border-0"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                    </button>
                                                    : ''}
                                                {x.amountType == 'Subtraction' ?
                                                    <button className="debit-sign text-white p-3 m-0 d-flex align-items-center justify-content-center border-0" value="Reset"
                                                    >
                                                        <i className="fa fa-minus"></i>
                                                    </button>
                                                    : ''}
                                            </div>
                                            <div class="col-md-5">
                                                <div class="card-element">
                                                    <div class="pb-2">
                                                        <label for="contact">Amount</label>
                                                    </div>
                                                    <input type="text" id={idx} value={x.newAmountAdd}
                                                        name="newAmountAdd"
                                                        onChange={event => this.handleFormChange(idx, event, "Amount")}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="card-element">
                                                    <div class="pb-2">
                                                        <label for="contact">Remark</label>
                                                    </div>
                                                    <input type="text" id={idx} value={x.newRemark}
                                                        name="newRemark"
                                                        onChange={event => this.handleFormChange(idx, event, "Remark")}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-1'>
                                                <button class="card-header-btn align-self-center ml-0 d-block mt-3" onClick={(e) => this.removeFields(idx)}>
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}



                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <label for="remark">Remark</label>
                                    </div>
                                    <textarea name="" id="credit_remark" rows="2" maxLength="140" value={description} onChange={(e) => this.handleChangeCredit(e, "description")}></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="overflow-hidden mb-2">
                            <button class="float-right card-header-product-btn w-auto px-2 align-self-center" id="addProductCredit" onClick={() => this.setState({ IsProductSelected: true })}>
                                <i class="fas fa-plus"></i> Product
                            </button>
                        </div>
                        <div class="add-product mt-4" id="productCredit" style={IsProductSelected ? { display: "block" } : null}>
                            <div class="product-close-button" onClick={() => this.setState({ IsProductSelected: false })}>
                                <i class="fas fa-times align-item-center"></i>
                            </div>
                            <div class="row" id="product-container">

                                <div class="col-md-4">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Product Name</label>
                                        </div>
                                        {/* <div class="form-group mt-2 w-100">
                                            <Select class="form-control" id="credit_product_name" value={ProductName}
                                                isClearable={true}
                                                isSearchable={true} onChange={(e) => this.handleChange(e, "ProductName")}>
                                                <option>Select Product Name</option>
                                                {ProductList.map(x => {
                                                    return (<option class="select-option" value={x.product_name}>
                                                        {x.product_name}</option>
                                                    )
                                                })}
                                            </Select>
                                        </div> */}
                                        <div class="form-group mt-2 w-100">
                                            <Select
                                                className="select2-hidden-accessible"
                                                classNamePrefix="select"
                                                placeholder="Select Product Name"
                                                value={this.state.ProductName}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.setState({ ProductName: e.value })}
                                                //   name="color"
                                                options={ProductList.length ? ProductList.map(x => { return { value: x.product_name, label: x.product_name + "(" + x.product_name + ")", ProductName: x.product_name } }) : []}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Quantity</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_quantity" min="0" step="1" value={Quantity} onChange={(e) => this.handleChange(e, "Quantity")} />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Rate</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_rate" min="0" step="1" value={Rate} onChange={(e) => this.handleChange(e, "Rate")} />
                                    </div>
                                    <div id="phone_credit_error" class="d-none"></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="remark">Remark</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_remark" maxLength="100" value={Remark} onChange={(e) => this.handleChange(e, "Remark")} />
                                    </div>

                                </div>
                            </div>

                            {IsTaxSelected ?
                                <div class="add-tax-credit" style={{ display: "block" }}>
                                    {TaxArr.map((x, idx) => {
                                        return (
                                            idx === 0 ?
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="tax">Tax Name</label>
                                                            </div>
                                                            <div class="form-group mt-2 w-100">
                                                                <select class="form-control" id="credit_tax_default" value={x.TaxID} onChange={(e) => {
                                                                    var filterOutput = TaxtList.filter(x => x.pt_id === Number(e.target.value))
                                                                    if (filterOutput.length) {
                                                                        var tempTaxArr = TaxArr;
                                                                        tempTaxArr[idx].TaxID = Number(filterOutput[0].pt_id);
                                                                        tempTaxArr[idx].TaxName = filterOutput[0].tax_name;
                                                                        tempTaxArr[idx].Value = filterOutput[0].tax_percent;
                                                                        tempTaxArr[idx].Amount = parseFloat((Number(this.state.Quantity) * Number(this.state.Rate)) * Number(filterOutput[0].tax_percent) / 100).toFixed(2)
                                                                        this.setState({ TaxArr: tempTaxArr })
                                                                    }
                                                                }}>

                                                                    <option value="">Select Tax</option>
                                                                    {TaxtList.map(content => {
                                                                        return (
                                                                            <option class="select-option" value={content.pt_id}>{content.tax_name}</option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Value</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_value" min="0" step="1" value={x.Value} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Value = tempTaxArr[idx].Value;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Amount</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_amount" min="0" step="1" value={x.Amount} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Amount = tempTaxArr[idx].Amount;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div class="row" id="product_tax_1">
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="tax">Tax Name</label>
                                                            </div>
                                                            <div class="form-group mt-2 w-100">
                                                                <select class="form-control" id="credit_tax_default" value={x.TaxID} onChange={(e) => {
                                                                    var filterOutput = TaxtList.filter(x => x.pt_id === Number(e.target.value))
                                                                    if (filterOutput.length) {
                                                                        var tempTaxArr = TaxArr;
                                                                        tempTaxArr[idx].TaxID = Number(filterOutput[0].pt_id);
                                                                        tempTaxArr[idx].TaxName = filterOutput[0].tax_name;
                                                                        tempTaxArr[idx].Value = filterOutput[0].tax_percent;
                                                                        tempTaxArr[idx].Amount = parseFloat((Number(this.state.Quantity) * Number(this.state.Rate)) * Number(filterOutput[0].tax_percent) / 100).toFixed(2)
                                                                        this.setState({ TaxArr: tempTaxArr })
                                                                    }
                                                                }}>
                                                                    <option value="">Select Tax</option>
                                                                    {TaxtList.map(content => {
                                                                        return (<option class="select-option" value={content.pt_id}>
                                                                            {content.tax_name}</option>)
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Value</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_value" min="0" step="1" value={x.Value} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Value = tempTaxArr[idx].Value;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Amount</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_amount" min="0" step="1" value={x.Amount} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Amount = tempTaxArr[idx].Amount;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 align-self-center">
                                                        <button class="card-header-btn align-self-center d-block mt-4" onClick={() => {
                                                            var tempTaxArr = TaxArr;
                                                            tempTaxArr.splice(idx, 1);
                                                            this.setState({ TaxArr: tempTaxArr })
                                                        }} id="addTaxDebit">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                        )
                                    })}
                                </div>
                                : null
                            }

                            <div class="row">
                                <div class="tax-check col-md-8 mb-1 d-flex">
                                    <div class="d-flex">
                                        <div class="align-self-center">
                                            <input type="checkbox" id="checkTaxCredit" class="checkbox-checkout mt-1 mr-1 align-self-center" checked={IsTaxSelected} onChange={(e) => this.setState({ IsTaxSelected: e.target.checked })} />
                                        </div>
                                        <div class="align-self-center">Tax</div>
                                    </div>
                                    {IsTaxSelected ?
                                        <button class="card-header-product-btn w-auto px-2 align-self-center" id="addTaxCredit" onClick={() => this.setState({
                                            TaxArr: [...TaxArr, {
                                                TaxID: '',
                                                TaxName: '',
                                                Value: '',
                                                Amount: ''
                                            }]
                                        })} style={{ display: 'inline-block' }}>
                                            <i class="fas fa-plus"></i> Add Tax
                                        </button>
                                        : null
                                    }
                                </div>

                                <div class="col-md-4 align-self-center">

                                    <div class="d-flex justify-content-center">
                                        <div class="align-self-center">
                                            <button class="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0" onClick={() => this.addTransactionProductDetail()}>
                                                <i class="fas fa-check"></i>
                                            </button>
                                        </div>
                                        <div class="align-self-center">
                                            <button class="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset" onClick={() => this.setState({
                                                ProductName: '',
                                                Quantity: '',
                                                Rate: '',
                                                Remark: '',
                                                TaxArr: [
                                                    {
                                                        TaxID: '',
                                                        TaxName: '',
                                                        Value: '',
                                                        Amount: ''
                                                    }
                                                ],
                                                IsTaxSelected: false
                                            })}>
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {TransactionProductDetailArr.length ?
                            <div class="table-responsive product-table mt-2">
                                <table class="table table-striped borderless">
                                    <thead id="product_credit_head">
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Sub Total</th>
                                            <th scope="col">Remark</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Value</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody id="product_credit_description">
                                        {TransactionProductDetailArr?.map((x, idx) => {
                                            return (
                                                <tr id="credit_row_1">
                                                    <td scope="col">{x.ProductName}</td>
                                                    <td scope="col">{x.Quantity}</td>
                                                    <td scope="col">{x.Rate}</td>
                                                    <td scope="col">
                                                        <span>{x.SubTotal.length ? x.SubTotal === 0 ? null : CommonConfig.getIndianFormatAmount(x.SubTotal) : ''}</span>
                                                        {/* {x.SubTotal} */}
                                                    </td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Remark) ? '-' : x.Remark}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Tax) ? '-' : x.Tax}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Value) ? 0 : x.Value} (%)</td>
                                                    <td scope="col">{x.Amount}</td>
                                                    <td scope="col" class="position-relative">
                                                        <span>{x.Total.length ? x.Total === 0 ? null : CommonConfig.getIndianFormatAmount(x.Total) : ''}</span>
                                                        {/* {x.Total} */}
                                                        &nbsp;
                                                        <span class="product-list-delete" onClick={() => {
                                                            var tempTransc = TransactionProductDetailArr;
                                                            let currentMainAmount = this.state.credit_amount;
                                                            let newAmount = 0;

                                                            currentMainAmount -= Number(x.Total);
                                                            newAmount = parseFloat(currentMainAmount);

                                                            this.setState({ credit_amount: parseFloat(newAmount) });
                                                            tempTransc.splice(idx, 1);
                                                            this.setState({ TransactionProductDetailArr: tempTransc })
                                                        }}>
                                                            <i class="far fa-trash-alt debit-color"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                    </div>
                    <div class="modal-footer">
                        <div class="text-center">
                            <button type="button" class="btn btn-success Submit-btn" id="credit_save_button" onClick={(e) => this.submitCredit(e)}>Save</button>
                        </div>
                    </div>

                </Modal>
                {/* Credit model end */}
                {/* Debit model start */}
                <Modal show={showDebitModal} dialogClassName="full-screen-modal" className="full-screen-modal" onHide={() => this.handleClose("Debit")}>
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">Add Debit/ Payment/ Sales</h5>
                        <div class="d-flex">
                            <button type="button" class="align-self-center card-header-btn" onClick={() => this.handleClose("Debit")}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body user-modal pb-4 py-0">
                        <div class="add-party" id="addCreditParty">
                            <div class="party-close-button">
                                <i class="fas fa-times align-item-center"></i>
                            </div>
                            <div class="row">

                                <div class="col-md-5">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Add Party Name</label>
                                        </div>
                                        <input type="text" maxlength="15" id="credit_user_register" />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Contact No</label>
                                        </div>
                                        <input type="text" onkeyup="userUsernameDetails(event,'credit')" maxlength="10" id="credit_contact_register" class="form-control" />
                                    </div>
                                    <div id="phone_credit_error" class="d-none"></div>
                                </div>
                                <div class="col-md-2 mt-2 text-center d-flex justify-content-center">
                                    <button type="button" class="Submit-btn align-self-center py-1">Submit</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <label for="username">Date</label>
                                    </div>
                                    {/* <DatePicker className="input-group date datepicker_entry"
                                        value={created_on} onChange={(e) => this.handleChangeDebit(e, "created_on")}
                                        format="dd-MM-y"
                                        dayPlaceholder="dd"
                                        monthPlaceholder="mm"
                                        yearPlaceholder="yyyy"
                                        showLeadingZeros={false}
                                    /> */}
                                    <DatePicker
                                        animation
                                        editable={false}
                                        type="input-icon"
                                        placeholder="Date"
                                        format="DD/MM/YYYY"
                                        value={created_on}
                                        onChange={(e) => this.handleChangeDebit(e, "created_on")}
                                    />
                                    {/* <div id="datepicker_entry" class="input-group date datepicker_entry" data-date-format="dd-mm-yyyy">
                                        <input type="date" id="credit_date" required
                                            value={created_on} onChange={(e) => this.handleChangeDebit(e, "created_on")}
                                        />

                                        <span class="input-group-addon">
                                            <i class="glyphicon glyphicon-calendar"></i>
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card-element ">
                                    <div class="pb-2">
                                        <label for="plan">Select Party Name</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="form-group w-100">
                                            {/* <select class="form-control select2-hidden-accessible" id="credit_party" data-select2-id="credit_party" tabindex="-1" aria-hidden="true"
                                                value={requested_to} onChange={(e) => this.handleChangeDebit(e, "requested_to")}
                                            >
                                                <option data-select2-id="4">Select Party</option>

                                                {LedgerList.map(x => {
                                                    return (<option class="select-option" value={x.ledger_user_id}>
                                                        {x.ledger_username} ({x.ledger_user_id})</option>
                                                    )
                                                })}
                                            </select> */}
                                            <Select
                                                ref={innerRef => this.innerRef = innerRef}
                                                autoFocus={true}
                                                className="select2-hidden-accessible"
                                                classNamePrefix="select"
                                                placeholder="Select Party"
                                                value={requested_to}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.handleChangeDebit(e, "requested_to")}
                                                //   name="color"
                                                options={LedgerList.length ? LedgerList.map(x => { return { value: x.ledger_user_id, label: x.ledger_username + "(" + x.ledger_user_id + ")" } }) : []}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Escape" || event.which === 27) {
                                                        // write your logic here.
                                                        this.setState({ showDebitModal: false })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div class="overflow-hidden mb-3">
                                            <button class="float-right card-header-product-btn w-auto px-2 align-self-center" onClick={() => this.setState({ showLedgerAddModal: true })}>
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <label for="contact">Contact No.</label>
                                    </div>

                                    <input type="text" disabled id="credit_contact" readonly=""
                                        value={CommonConfig.isObjectEmpty(requested_to) ? '' : requested_to.value} onChange={(e) => this.handleChangeDebit(e, "requested_to")}
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <div className="d-flex align-items-center">
                                            <label for="password" className='mr-2'>Amount</label>
                                            <div className="align-self-center">
                                                <button className="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0"
                                                    onClick={() => this.addField("Addition")}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset"
                                                    onClick={() => this.addField("Subtraction")}
                                                >
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" id="credit_amount" min="0" step="1"
                                        value={debit_amount} ref={(input) => { this.refInput = input; }} onChange={(e) => this.handleChangeDebit(e, "debit_amount")}
                                    />

                                </div>
                            </div>
                        </div>
                        <div>
                            {creditDebitArr.map((x, idx) => {
                                return (
                                    <div className='row'>
                                        <div className='col-md-6'></div>
                                        <div className='col-md-6'>


                                            <div class="row align-items-center" key={idx}>
                                                <div className="col-md-1 mt-3">
                                                    {x.amountType == 'Addition' ?
                                                        <button className="credit-sign text-white p-3 m-0 d-flex align-items-center justify-content-center border-0"
                                                        >
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                        : ''}
                                                    {x.amountType == 'Subtraction' ?
                                                        <button className="debit-sign text-white p-3 m-0 d-flex align-items-center justify-content-center border-0" value="Reset"
                                                        >
                                                            <i className="fa fa-minus"></i>
                                                        </button>
                                                        : ''}
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="card-element">
                                                        <div class="pb-2">
                                                            <label for="contact">Amount</label>
                                                        </div>
                                                        <input type="text" id={idx} value={x.newAmountAdd}
                                                            name="newAmountAdd"
                                                            onChange={event => this.handleFormChange(idx, event, "Amount")}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="card-element">
                                                        <div class="pb-2">
                                                            <label for="contact">Remark</label>
                                                        </div>
                                                        <input type="text" id={idx} value={x.newRemark}
                                                            name="newRemark"
                                                            onChange={event => this.handleFormChange(idx, event, "Remark")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-1'>
                                                    <button class="card-header-btn align-self-center ml-0 d-block mt-3" onClick={(e) => this.removeFields(idx)}>
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}

                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-element">
                                    <div class="pb-2">
                                        <label for="remark">Remark</label>
                                    </div>
                                    <textarea name="" id="credit_remark" rows="2" maxlength="110"
                                        value={description} onChange={(e) => this.handleChangeDebit(e, "description")}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-hidden mb-2">
                            <button class="float-right card-header-product-btn w-auto px-2 align-self-center" id="addProductCredit" onClick={() => this.setState({ IsProductSelected: true })}>
                                <i class="fas fa-plus"></i> Product
                            </button>
                        </div>
                        <div class="add-product mt-4" id="productCredit" style={IsProductSelected ? { display: "block" } : null}>
                            <div class="product-close-button" onClick={() => this.setState({ IsProductSelected: false })}>
                                <i class="fas fa-times align-item-center"></i>
                            </div>
                            <div class="row" id="product-container">

                                <div class="col-md-4">
                                    <div class="card-element">
                                        {/* {/* <div class="pb-2">
                                            <label for="username">Product Name</label>
                                        </div> */}
                                        {/* <div class="form-group mt-2 w-100">
                                            <Select class="form-control" id="credit_product_name" value={ProductName} isClearable={true}
                                                isSearchable={true} onChange={(e) => this.handleChange(e, "ProductName")}>
                                                <option>Select Product Name</option>
                                                {ProductList.map(x => {
                                                    return (<option class="select-option" value={x.product_name}>
                                                        {x.product_name}</option>
                                                    )
                                                })}
                                            </Select>
                                        </div>  */}
                                        <div class="form-group mt-2 w-100">
                                            {/* <Select
                                                className="select2-hidden-accessible"
                                                classNamePrefix="select"
                                                placeholder="Select Product Name"
                                                value={this.state.ProductName}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.setState({ ProductName: e })}
                                                //   name="color"
                                                options={ProductList.length ? ProductList.map(x => { return { value: x.product_name, label: x.product_name + "(" + x.product_name + ")", ProductName: x.product_name } }) : []}
                                            /> */}
                                            <div class="form-group mt-2 w-100">
                                                <Select
                                                    className="select2-hidden-accessible"
                                                    classNamePrefix="select"
                                                    placeholder="Select Product Name"
                                                    value={this.state.ProductName}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.setState({ ProductName: e.value })}
                                                    //   name="color"
                                                    options={ProductList.length ? ProductList.map(x => { return { value: x.product_name, label: x.product_name + "(" + x.product_name + ")", ProductName: x.product_name } }) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Quantity</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_quantity" min="0" step="1" value={Quantity} onChange={(e) => this.handleChange(e, "Quantity")} />
                                    </div>
                                    <div id="phone_credit_error" class="d-none"></div>
                                </div>
                                <div class="col-md-2">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Rate</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_rate" min="0" step="1" value={Rate} onChange={(e) => this.handleChange(e, "Rate")} />
                                    </div>
                                    <div id="phone_credit_error" class="d-none"></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="remark">Remark</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_remark" maxlength="100" value={Remark} onChange={(e) => this.handleChange(e, "Remark")} />
                                    </div>

                                </div>
                            </div>

                            {IsTaxSelected ?
                                <div class="add-tax-credit" style={{ display: "block" }}>
                                    {TaxArr.map((x, idx) => {
                                        return (
                                            idx === 0 ?
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="tax">Tax Name</label>
                                                            </div>
                                                            <div class="form-group mt-2 w-100">
                                                                <select class="form-control" id="credit_tax_default" value={x.TaxID} onChange={(e) => {
                                                                    var filterOutput = TaxtList.filter(x => x.pt_id === Number(e.target.value))
                                                                    if (filterOutput.length) {
                                                                        var tempTaxArr = TaxArr;
                                                                        tempTaxArr[idx].TaxID = Number(filterOutput[0].pt_id);
                                                                        tempTaxArr[idx].TaxName = filterOutput[0].tax_name;
                                                                        tempTaxArr[idx].Value = filterOutput[0].tax_percent;
                                                                        tempTaxArr[idx].Amount = parseFloat((Number(this.state.Quantity) * Number(this.state.Rate)) * Number(filterOutput[0].tax_percent) / 100).toFixed(2)
                                                                        this.setState({ TaxArr: tempTaxArr })
                                                                    }
                                                                }}>

                                                                    <option value="">Select Tax</option>
                                                                    {TaxtList.map(content => {
                                                                        return (
                                                                            <option class="select-option" value={content.pt_id}>{content.tax_name}</option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Value</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_value" min="0" step="1" value={x.Value} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Value = tempTaxArr[idx].Value;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Amount</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_amount" min="0" step="1" value={x.Amount} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Amount = tempTaxArr[idx].Amount;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div class="row" id="product_tax_1">
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="tax">Tax Name</label>
                                                            </div>
                                                            <div class="form-group mt-2 w-100">
                                                                <select class="form-control" id="credit_tax_default" value={x.TaxID} onChange={(e) => {
                                                                    var filterOutput = TaxtList.filter(x => x.pt_id === Number(e.target.value))
                                                                    if (filterOutput.length) {
                                                                        var tempTaxArr = TaxArr;
                                                                        tempTaxArr[idx].TaxID = Number(filterOutput[0].pt_id);
                                                                        tempTaxArr[idx].TaxName = filterOutput[0].tax_name;
                                                                        tempTaxArr[idx].Value = filterOutput[0].tax_percent;
                                                                        tempTaxArr[idx].Amount = parseFloat((Number(this.state.Quantity) * Number(this.state.Rate)) * Number(filterOutput[0].tax_percent) / 100).toFixed(2)
                                                                        this.setState({ TaxArr: tempTaxArr })
                                                                    }
                                                                }}>
                                                                    <option value="">Select Tax</option>
                                                                    {TaxtList.map(content => {
                                                                        return (<option class="select-option" value={content.pt_id}>
                                                                            {content.tax_name}</option>)
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Value</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_value" min="0" step="1" value={x.Value} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Value = tempTaxArr[idx].Value;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Amount</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_amount" min="0" step="1" value={x.Amount} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Amount = tempTaxArr[idx].Amount;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 align-self-center">
                                                        <button class="card-header-btn align-self-center d-block mt-4" onClick={() => {
                                                            var tempTaxArr = TaxArr;
                                                            tempTaxArr.splice(idx, 1);
                                                            this.setState({ TaxArr: tempTaxArr })
                                                        }} id="addTaxDebit">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                        )
                                    })}
                                </div>
                                : null
                            }

                            <div class="row">
                                <div class="tax-check col-md-8 mb-1 d-flex">
                                    <div class="d-flex">
                                        <div class="align-self-center">
                                            <input type="checkbox" id="checkTaxCredit" class="checkbox-checkout mt-1 mr-1 align-self-center" checked={IsTaxSelected} onChange={(e) => this.setState({ IsTaxSelected: e.target.checked })} />
                                        </div>
                                        <div class="align-self-center">Tax</div>
                                    </div>
                                    {IsTaxSelected ?
                                        <button class="card-header-product-btn w-auto px-2 align-self-center" id="addTaxCredit" onClick={() => this.setState({
                                            TaxArr: [...TaxArr, {
                                                TaxID: '',
                                                TaxName: '',
                                                Value: '',
                                                Amount: ''
                                            }]
                                        })} style={{ display: 'inline-block' }}>
                                            <i class="fas fa-plus"></i> Add Tax
                                        </button>
                                        : null
                                    }
                                </div>

                                <div class="col-md-4 align-self-center">

                                    <div class="d-flex justify-content-center">
                                        <div class="align-self-center">
                                            <button class="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0" onClick={() => this.addTransactionProductDetail()}>
                                                <i class="fas fa-check"></i>
                                            </button>
                                        </div>
                                        <div class="align-self-center">
                                            <button class="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset" onClick={() => this.setState({
                                                ProductName: '',
                                                Quantity: '',
                                                Rate: '',
                                                Remark: '',
                                                TaxArr: [
                                                    {
                                                        TaxID: '',
                                                        TaxName: '',
                                                        Value: '',
                                                        Amount: ''
                                                    }
                                                ],
                                                IsTaxSelected: false
                                            })}>
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {TransactionProductDetailArr.length ?
                            <div class="table-responsive product-table mt-2">
                                <table class="table table-striped borderless">
                                    <thead id="product_credit_head">
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Sub Total</th>
                                            <th scope="col">Remark</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Value</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody id="product_credit_description">
                                        {TransactionProductDetailArr.map((x, idx) => {
                                            return (
                                                <tr id="credit_row_1">
                                                    <td scope="col">{x.ProductName}</td>
                                                    <td scope="col">{x.Quantity}</td>
                                                    <td scope="col">{x.Rate}</td>
                                                    <td scope="col">
                                                        <span>{x.SubTotal.length ? x.SubTotal === 0 ? null : CommonConfig.getIndianFormatAmount(x.SubTotal) : ''}</span>
                                                    </td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Remark) ? '-' : x.Remark}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Tax) ? '-' : x.Tax}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Value) ? 0 : x.Value} (%)</td>
                                                    <td scope="col">{x.Amount}</td>
                                                    <td scope="col" class="position-relative">
                                                        {/* {x.Total} */}
                                                        <span>{x.Total.length ? x.Total === 0 ? null : CommonConfig.getIndianFormatAmount(x.Total) : ''}</span>&nbsp;
                                                        <span class="product-list-delete" onClick={() => {
                                                            var tempTransc = TransactionProductDetailArr;
                                                            let currentMainAmount = this.state.debit_amount;
                                                            let newAmount = 0;

                                                            currentMainAmount -= Number(x.Total);
                                                            newAmount = parseFloat(currentMainAmount);

                                                            this.setState({ debit_amount: parseFloat(newAmount) });
                                                            tempTransc.splice(idx, 1);
                                                            this.setState({ TransactionProductDetailArr: tempTransc })
                                                        }}>
                                                            <i class="far fa-trash-alt debit-color"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                    </div>
                    <div class="modal-footer">
                        <div class="text-center">
                            <button type="button" class="btn btn-success Submit-btn" id="credit_save_button" onClick={(e) => this.submitDebit(e)}>Save</button>
                        </div>
                    </div>

                </Modal>
                {/* Debit  model end */}
                {/* Statment edit model  start*/}
                <Modal show={showEditCreditModal} dialogClassName="full-screen-modal" className="full-screen-modal" onHide={() => this.handleClose("EditCredit")}>
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">Edit Statement
                        </h5>
                        <div class="d-flex">
                            <button type="button" class="align-self-center card-header-btn" onClick={() => this.handleClose("EditCredit")}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>

                    <div class="modal-body user-modal pb-4 py-0">
                        <div>

                            <div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card-element">
                                            <div class="pb-2">
                                                <label for="username">Select Date</label>
                                            </div>
                                            {/* <div id="datepicker_entry" class="input-group date datepicker_entry" data-date-format="dd-mm-yyyy">
                                        <input type="date" id="credit_date" required
                                            value={edit_created_on}
                                            onChange={(e) => this.handleChangeCredit(e, "edit_created_on")} />
                                        <span class="input-group-addon">
                                            <i class="glyphicon glyphicon-calendar"></i>
                                        </span>
                                    </div> */}
                                            {/* <DatePicker className="input-group date datepicker_entry"
                                        value={edit_created_on} onChange={(e) => this.handleChangeCredit(e, "edit_created_on")}
                                        format="dd-MM-y"
                                        dayPlaceholder="dd"
                                        monthPlaceholder="mm"
                                        yearPlaceholder="yyyy"
                                        showLeadingZeros={false}
                                    /> */}
                                            <DatePicker
                                                animation
                                                editable={false}
                                                type="input-icon"
                                                placeholder="Date"
                                                format="DD/MM/YYYY"
                                                value={edit_created_on}
                                                onChange={(e) => this.handleChangeCredit(e, "edit_created_on")}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card-element">
                                            <div class="pb-2">
                                                <div className="d-flex align-items-center">
                                                    <label for="password" className='mr-2'>Amount</label>
                                                    <div className="align-self-center">
                                                        <button className="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0"
                                                            onClick={() => this.addField("Addition")}

                                                        >
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <button className="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset" onClick={() => {
                                                            this.addField("Subtraction")

                                                        }}>
                                                            <i className="fa fa-minus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="text" id="credit_amount" min="0" step="1"
                                                value={edit_amount}
                                                onChange={(e) => this.handleChangeCredit(e, "edit_amount")} />
                                        </div>

                                    </div>
                                </div>
                                {
                                    creditDebitArr.map((x, idx) => {
                                        return (

                                            <>

                                                <div className='row'>
                                                    <div className='col-md-6'></div>
                                                    <div className='col-md-6'>
                                                        <div class="row align-items-center" key={idx}>
                                                            <div className="col-md-1 mt-3">
                                                                {x.amountType == 'Addition' ?
                                                                    <button className="credit-sign text-white p-3 m-0 d-flex align-items-center justify-content-center border-0"
                                                                    >
                                                                        <i className="fas fa-plus"></i>
                                                                    </button>
                                                                    : ''}
                                                                {x.amountType == 'Subtraction' ?
                                                                    <button className="debit-sign text-white p-3 m-0 d-flex align-items-center justify-content-center border-0" value="Reset"
                                                                    >
                                                                        <i className="fa fa-minus"></i>
                                                                    </button>
                                                                    : ''}
                                                            </div>
                                                            <div class="col-md-5">
                                                                <div class="card-element">
                                                                    <div class="pb-2">
                                                                        <label for="contact">Amount</label>
                                                                    </div>
                                                                    <input type="text" id={idx} value={x.newAmountAdd}
                                                                        name="newAmountAdd"
                                                                        onChange={event => this.handleFormChange(idx, event, "Amount")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5">
                                                                <div class="card-element">
                                                                    <div class="pb-2">
                                                                        <label for="contact">Remark</label>
                                                                    </div>
                                                                    <input type="text" id={idx} value={x.newRemark}
                                                                        name="newRemark"
                                                                        onChange={event => this.handleFormChange(idx, event, "Remark")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-1'>
                                                                <button class="card-header-btn align-self-center ml-0 d-block mt-3" onClick={(e) => this.removeFields(idx)}>
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    })
                                }
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card-element">
                                            <div class="pb-2">
                                                <label for="remark">Description</label>
                                            </div>
                                            <textarea name="" id="credit_remark" rows="2" maxlength="100"
                                                value={edit_description}
                                                onChange={(e) => this.handleChangeCredit(e, "edit_description")}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div class="overflow-hidden mb-2">
                            <button class="float-right card-header-product-btn w-auto px-2 align-self-center" id="addProductCredit" onClick={() => this.setState({ IsProductSelected: true })}>
                                <i class="fas fa-plus"></i> Product
                            </button>
                        </div>
                        <div class="add-product mt-4" id="productCredit" style={IsProductSelected ? { display: "block" } : null}>
                            <div class="product-close-button" onClick={() => this.setState({ IsProductSelected: false })}>
                                <i class="fas fa-times align-item-center"></i>
                            </div>
                            <div class="row" id="product-container">

                                <div class="col-md-4">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Product Name</label>
                                        </div>
                                        {/* <div class="form-group mt-2 w-100">
                                            <Select class="form-control" id="credit_product_name" value={ProductName} onChange={(e) => this.handleChange(e, "ProductName")}>
                                                <option>Select Product Name</option>
                                                {ProductList.map(x => {
                                                    return (<option class="select-option" value={x.product_name}>
                                                        {x.product_name}</option>
                                                    )
                                                })}
                                            </Select>
                                        </div> */}
                                        <div class="form-group mt-2 w-100">
                                            <Select
                                                className="select2-hidden-accessible"
                                                classNamePrefix="select"
                                                placeholder="Select Product Name"
                                                value={this.state.ProductName}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.setState({ ProductName: e.value })}
                                                //   name="color"
                                                options={ProductList.length ? ProductList.map(x => { return { value: x.product_name, label: x.product_name + "(" + x.product_name + ")", ProductName: x.product_name } }) : []}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Quantity</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_quantity" min="0" step="1" value={Quantity} onChange={(e) => this.handleChange(e, "Quantity")} />
                                    </div>
                                    <div id="phone_credit_error" class="d-none"></div>
                                </div>
                                <div class="col-md-2">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="username">Rate</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_rate" min="0" step="1" value={Rate} onChange={(e) => this.handleChange(e, "Rate")} />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card-element">
                                        <div class="pb-2">
                                            <label for="remark">Remark</label>
                                        </div>
                                        <input type="text" className="mt-2 background-white" id="product_credit_remark" maxlength="100" value={Remark} onChange={(e) => this.handleChange(e, "Remark")} />
                                    </div>

                                </div>
                            </div>

                            {IsTaxSelected ?
                                <div class="add-tax-credit" style={{ display: "block" }}>
                                    {TaxArr.map((x, idx) => {
                                        return (
                                            idx === 0 ?
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="tax">Tax Name</label>
                                                            </div>
                                                            <div class="form-group mt-2 w-100">
                                                                <select class="form-control" id="credit_tax_default" value={x.TaxID} onChange={(e) => {
                                                                    var filterOutput = TaxtList.filter(x => x.pt_id === Number(e.target.value))
                                                                    if (filterOutput.length) {
                                                                        var tempTaxArr = TaxArr;
                                                                        tempTaxArr[idx].TaxID = Number(filterOutput[0].pt_id);
                                                                        tempTaxArr[idx].TaxName = filterOutput[0].tax_name;
                                                                        tempTaxArr[idx].Value = filterOutput[0].tax_percent;
                                                                        tempTaxArr[idx].Amount = parseFloat((Number(this.state.Quantity) * Number(this.state.Rate)) * Number(filterOutput[0].tax_percent) / 100).toFixed(2)
                                                                        this.setState({ TaxArr: tempTaxArr })
                                                                    }
                                                                }}>

                                                                    <option value="">Select Tax</option>
                                                                    {TaxtList.map(content => {
                                                                        return (
                                                                            <option class="select-option" value={content.pt_id}>{content.tax_name}</option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Value</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_value" min="0" step="1" value={x.Value} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Value = tempTaxArr[idx].Value;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Amount</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_amount" min="0" step="1" value={x.Amount} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Amount = tempTaxArr[idx].Amount;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div class="row" id="product_tax_1">
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="tax">Tax Name</label>
                                                            </div>
                                                            <div class="form-group mt-2 w-100">
                                                                <select class="form-control" id="credit_tax_default" value={x.TaxID} onChange={(e) => {
                                                                    var filterOutput = TaxtList.filter(x => x.pt_id === Number(e.target.value))
                                                                    if (filterOutput.length) {
                                                                        var tempTaxArr = TaxArr;
                                                                        tempTaxArr[idx].TaxID = Number(filterOutput[0].pt_id);
                                                                        tempTaxArr[idx].TaxName = filterOutput[0].tax_name;
                                                                        tempTaxArr[idx].Value = filterOutput[0].tax_percent;
                                                                        tempTaxArr[idx].Amount = parseFloat((Number(this.state.Quantity) * Number(this.state.Rate)) * Number(filterOutput[0].tax_percent) / 100).toFixed(2)
                                                                        this.setState({ TaxArr: tempTaxArr })
                                                                    }
                                                                }}>
                                                                    <option value="">Select Tax</option>
                                                                    {TaxtList.map(content => {
                                                                        return (<option class="select-option" value={content.pt_id}>
                                                                            {content.tax_name}</option>)
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Value</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_value" min="0" step="1" value={x.Value} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Value = tempTaxArr[idx].Value;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Value = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card-element">
                                                            <div class="pb-2">
                                                                <label for="username">Amount</label>
                                                            </div>
                                                            <input type="text" className="mt-2 background-white" id="product_credit_tax_amount" min="0" step="1" value={x.Amount} onChange={(e) => {
                                                                var tempTaxArr = TaxArr;
                                                                if (CommonConfig.isEmpty(e.target.value)) {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
                                                                    tempTaxArr[idx].Amount = tempTaxArr[idx].Amount;
                                                                }
                                                                else {
                                                                    tempTaxArr[idx].Amount = e.target.value;
                                                                }
                                                                this.setState({ TaxArr: tempTaxArr })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 align-self-center">
                                                        <button class="card-header-btn align-self-center d-block mt-4" onClick={() => {
                                                            var tempTaxArr = TaxArr;
                                                            tempTaxArr.splice(idx, 1);
                                                            this.setState({ TaxArr: tempTaxArr })
                                                        }} id="addTaxDebit">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                        )
                                    })}
                                </div>
                                : null
                            }

                            <div class="row">
                                <div class="tax-check col-md-8 mb-1 d-flex">
                                    <div class="d-flex">
                                        <div class="align-self-center mr-1">
                                            <input type="checkbox" id="checkTaxCredit" class="checkbox-checkout mr-2" checked={IsTaxSelected} onChange={(e) => this.setState({ IsTaxSelected: e.target.checked })} />
                                        </div>
                                        <div class="align-self-center">Tax</div>
                                    </div>
                                    {IsTaxSelected ?
                                        <button class="card-header-product-btn w-auto px-2 align-self-center" id="addTaxCredit" onClick={() => this.setState({
                                            TaxArr: [...TaxArr, {
                                                TaxID: '',
                                                TaxName: '',
                                                Value: '',
                                                Amount: ''
                                            }]
                                        })} style={{ display: 'inline-block' }}>
                                            <i class="fas fa-plus"></i> Add Tax
                                        </button>
                                        : null
                                    }
                                </div>

                                <div class="col-md-4 align-self-center">

                                    <div class="d-flex justify-content-center">
                                        <div class="align-self-center">
                                            <button class="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0" onClick={() => this.addTransactionProductDetail()}>
                                                <i class="fas fa-check"></i>
                                            </button>
                                        </div>
                                        <div class="align-self-center">
                                            <button class="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset" onClick={() => this.setState({
                                                ProductName: '',
                                                Quantity: '',
                                                Rate: '',
                                                Remark: '',
                                                TaxArr: [
                                                    {
                                                        TaxID: '',
                                                        TaxName: '',
                                                        Value: '',
                                                        Amount: ''
                                                    }
                                                ],
                                                IsTaxSelected: false
                                            })}>
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {TransactionProductDetailArr.length ?
                            <div class="table-responsive product-table mt-2">
                                <table class="table table-striped borderless">
                                    <thead id="product_credit_head">
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Sub Total</th>
                                            <th scope="col">Remark</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Value</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody id="product_credit_description">
                                        {TransactionProductDetailArr?.map((x, idx) => {
                                            return (
                                                <tr id="credit_row_1">
                                                    <td scope="col">{x.ProductName}</td>
                                                    <td scope="col">{x.Quantity}</td>
                                                    {/* <td scope="col">{x.Rate}</td> */}
                                                    <td scope="col">{CommonConfig.isEmpty(x.Rate) ? '-' : CommonConfig.getIndianFormatAmount(x.Rate)}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.SubTotal) ? '-' : CommonConfig.getIndianFormatAmount(x.SubTotal)}</td>
                                                    {/* <td scope="col">{CommonConfig.isEmpty(x.SubTotal) ? '-' : parseFloat(x.SubTotal).toFixed(2)}</td> */}
                                                    <td scope="col">{CommonConfig.isEmpty(x.Remark) ? '-' : x.Remark}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Tax) ? '-' : x.Tax}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Value) ? 0 : x.Value} (%)</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.Amount) ? '-' : parseFloat(x.Amount).toFixed(2)}</td>
                                                    {/* <td scope="col"> <span>{CommonConfig.isEmpty(x.Amount) ? '-' : CommonConfig.getIndianFormatAmount(x.Amount)}</span></td> */}
                                                    <td scope="col" class="position-relative">
                                                        {/* {CommonConfig.isEmpty(x.Total) ? '-' : parseFloat(x.Total).toFixed(2)}&nbsp; */}
                                                        {<span>{CommonConfig.isEmpty(x.SubTotal) ? '-' : CommonConfig.getIndianFormatAmount(x.Total)}</span>}&nbsp;
                                                        <span class="product-list-delete" onClick={() => {
                                                            var tempTransc = TransactionProductDetailArr;
                                                            let currentMainAmount = this.state.edit_amount;
                                                            let newAmount = 0;

                                                            currentMainAmount -= Number(x.Total);
                                                            newAmount = parseFloat(currentMainAmount);

                                                            this.setState({ edit_amount: parseFloat(newAmount) });
                                                            tempTransc.splice(idx, 1);
                                                            this.setState({ TransactionProductDetailArr: tempTransc })
                                                        }}>
                                                            <i class="far fa-trash-alt debit-color"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            : null
                        }
                    </div>

                    <div class="modal-footer">
                        <div class="text-center">
                            <button type="button" class="btn btn-success Submit-btn" id="credit_save_button" onClick={(e) => this.UpdateTransactionDetail(e)}>Save</button>
                        </div>
                    </div>
                </Modal>
                {/* Statment edit model  end */}
                {/* Ledger Statement Modal start */}
                <Modal show={showLedgerModal} dialogClassName="full-screen-modal" className="statement-modal full-screen-modal" onHide={() => this.handleCloseLedger("Ledger")}>

                    <div class="modal-header border-bottom-0">

                        <h5 class="modal-title">
                            <span id="statement_username">{LedgerStatementArr.length ? LedgerStatementArr[0].ledger_username : ""}'s ({LedgerStatementArr.length ? LedgerStatementArr[0].ledger_user_id : ""})</span> Statement
                        </h5>

                        <div onClick={() => this.printCreditStatement(LedgerStatementArr)}>
                            Print
                        </div>

                        <div class="d-flex header-icon card-header-btn">
                            <button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={() => this.handleCloseLedger("Ledger")}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body pt-0">
                        <div class="row mb-3">
                            <div class="col-12 col-sm-6 col-lg-3 col-md-6 pr-sm-1 my-1 my-lg-0">
                                <div class="card-element mb-0">

                                    {/* <input type="date" name="start_date" id="statement_start_date" placeholder="Start Date" onChange={(e) => this.setState({ StartDate: e.target.value })} value={edit_created_on ? moment(this.state.StartDate).format('YYYY-MM-DD') : ''} /> <span class="input-group-addon"><i class="glyphicon glyphicon-calendar"></i></span> */}

                                    {/* <DatePicker className="input-group date datepicker_entry"
                                        value={this.state.StartDate} onChange={(e) => this.setState({ StartDate: e })}
                                        format="dd-MM-y"
                                        dayPlaceholder="dd"
                                        monthPlaceholder="mm"
                                        yearPlaceholder="yyyy"
                                        showLeadingZeros={false}
                                    /> */}
                                    <DatePicker
                                        animation
                                        editable={false}
                                        type="input-icon"
                                        placeholder="Start Date"
                                        format="DD/MM/YYYY"
                                        value={this.state.StartDate}
                                        onChange={(e) => this.setState({ StartDate: new Date(e) })}
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-3 col-md-6 pl-sm-1 my-1 my-lg-0">
                                <div class="card-element mb-0">

                                    {/* <input type="date" name="end_date" id="statement_end_date" placeholder="End Date" onChange={(e) => this.setState({ EndDate: e.target.value })} value={this.state.EndDate ? moment(this.state.EndDate).format('YYYY-MM-DD') : ''} />
                                    <span class="input-group-addon"><i class="glyphicon glyphicon-calendar"></i></span> */}
                                    {/* <DatePicker className="input-group date datepicker_entry"
                                        value={this.state.EndDate} onChange={(e) => this.setState({ EndDate: e })}
                                        format="dd-MM-y"
                                        dayPlaceholder="dd"
                                        monthPlaceholder="mm"
                                        yearPlaceholder="yyyy"
                                        showLeadingZeros={false}
                                    /> */}
                                    <DatePicker
                                        animation
                                        editable={false}
                                        type="input-icon"
                                        placeholder="End Date"
                                        format="DD/MM/YYYY"
                                        value={this.state.EndDate}
                                        onChange={(e) => this.setState({ EndDate: new Date(e) })}
                                    />
                                </div>
                            </div>
                            <div class="col-8 col-lg-4 my-1 my-lg-0 px-lg-1 align-self-center">
                                <div class="card-element mb-0">
                                    <input type="text" placeholder="Description" value={this.state.Description} onChange={(e) => this.setState({ Description: e.target.value })} id="filter_Description" />
                                </div>
                            </div>
                            <div class="col-4 col-lg-2 my-1 my-lg-0 px-lg-1 d-flex justify-content-center">
                                <div class="align-self-center">
                                    <button className="credit-sign text-white p-3 m-0 mr-2 d-flex align-items-center justify-content-center border-0" onClick={() => {
                                        this.getLedgerFilterStatement(LedgerStatementArr[0].ledger_user_id)
                                    }}>
                                        <i class="fas fa-check"></i>
                                    </button>
                                </div>
                                <div class="align-self-center">
                                    <button className="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0" value="Reset" onClick={() => {

                                        this.setState({ showLedgerModal: true, Description: '', StartDate: '', EndDate: '' })
                                        let data = {
                                            UserID: CommonConfig.loginData().contact_no,
                                            LedgerID: LedgerStatementArr[0].ledger_user_id,
                                            pdescription: '',
                                            StartDate: '',
                                            EndDate: '',
                                        }
                                        try {
                                            api.post("credit/getCreditDebitStatement", data).then(res => {
                                                if (res.success) {

                                                    this.setState({
                                                        LedgerStatementArr: res.data,

                                                    })
                                                }
                                            }).catch(err => { })
                                        }
                                        catch (err) { }

                                    }}>
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-auto">
                            <div class="table-area">
                                <div class="statement-table">
                                    <table class="table table-striped borderless">
                                        <thead>
                                            <tr>
                                                <th class="table-first" scope="col">Date &amp; Time</th>
                                                <th class="table-second" scope="col">Credit</th>

                                                <th class="table-third" scope="col">Debit</th>
                                                <th scope="col">Description</th>
                                                <th class="table-fifth-th" scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody id="statement-id" class="table-body">
                                            {LedgerStatementArr.length && !CommonConfig.isEmpty(LedgerStatementArr[0].created_on) && !CommonConfig.isEmpty(LedgerStatementArr[0].credit_amount) && !CommonConfig.isEmpty(LedgerStatementArr[0].debit_amount) ?
                                                LedgerStatementArr.map(x => {
                                                    return (
                                                        <tr>
                                                            <td class="table-first">
                                                                {x.created_on ? moment(x.created_on).format('DD MMM YYYY') : ''}</td>
                                                            <td class="credit-color table-second">{CommonConfig.getIndianFormatAmount(x.credit_amount)}</td>
                                                            <td class="debit-color table-third">{CommonConfig.getIndianFormatAmount(x.debit_amount)}</td>
                                                            <td class="table-fourth">{x.description}</td>
                                                            <td class="table-fifth">
                                                                <div class="d-flex align-item-center">
                                                                    {ledgerAccess.EditAccess == 1 ?
                                                                        <div data-toggle="modal" data-target="#productSection" class="statement-icon" onClick={() => this.GetCreditDebitListByID(x.transaction_id, x.ledger_user_id,)}><i class="fas fa-rupee-sign text-secondary"></i></div> : null}
                                                                    {ledgerAccess.DeleteAccess == 1 ?
                                                                        <div class="statement-icon" onClick={() => this.DeleteTransactionDetail(x.ledger_user_id, x.transaction_id, x.credit_amount, x.debit_amount)}>
                                                                            <i class="fas fa-trash text-secondary"></i>
                                                                        </div>
                                                                        : null}
                                                                    {productAccess.ViewAccess == 1 ?
                                                                        <div data-toggle="modal" data-target="#productSection" class="statement-icon" onClick={() => this.getProductStatementByTransactionID(x.transaction_id)}>
                                                                            <i class="fas fa-box-open text-secondary"></i>
                                                                        </div>
                                                                        : null}
                                                                    {ledgerAccess.EditAccess == 1 ?
                                                                        <div data-toggle="modal" data-target="#addSection" class="statement-icon" onClick={() => this.getEntryDetailByTransactionID(x.transaction_id, x.ledger_user_id)}>
                                                                            <i class="fas fa-edit text-secondary"></i>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <tr class="text-center font-weight-bold"><td>No record found.</td></tr>}


                                            <tr class="statement-footer1 font-weight-bolder border-top">
                                                <td class="table-first">Current Total</td>
                                                <td class="table-second">{LedgerStatementArr.length ? CommonConfig.getIndianFormatAmount(parseFloat(LedgerStatementArr[0].CreditCurrentTotal).toFixed(2)) : ''}</td>
                                                <td class="table-third">{LedgerStatementArr.length ? CommonConfig.getIndianFormatAmount(parseFloat(LedgerStatementArr[0].DebitCurrentTotal).toFixed(2)) : ''}</td>
                                                <td class="table-fourth"></td>
                                                <td class="table-fifth"></td>
                                            </tr>
                                            <tr class="thead-dark statement-footer2 font-weight-bolder" colspan="4">
                                                <td class="table-first">Closing Balance</td>
                                                <td class=" credit-color table-second">
                                                    <span>{LedgerStatementArr.length ? LedgerStatementArr[0].CreditClosingBalance === 0 ? null : CommonConfig.getIndianFormatAmount(LedgerStatementArr[0].CreditClosingBalance) : ''}</span>
                                                </td>
                                                <td class="debit-color table-third">
                                                    <span>{LedgerStatementArr.length ? LedgerStatementArr[0].DebitClosingBalance === 0 && LedgerStatementArr[0].CreditClosingBalance != 0 ? null : LedgerStatementArr[0].DebitClosingBalance === 0 && LedgerStatementArr[0].CreditClosingBalance === 0 ? CommonConfig.getIndianFormatAmount(parseFloat(0).toFixed(2)) : CommonConfig.getIndianFormatAmount(LedgerStatementArr[0].DebitClosingBalance) : ''}</span>
                                                </td>
                                                <td class="table-fourth"></td>
                                                <td class="table-fifth"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/*  Ledger Statement Modal end  */}
                {/*  Product Statement Modal start  */}
                <Modal show={showProductModal} dialogClassName="modal-dialog-centered modal-lg" className="right profile-modal" onHide={() => this.handleCloseProduct("Product")}>
                    {/* <div class="modal-dialog modal-dialog-centered modal-lg">

                        <div class="modal-content"> */}
                    <div class="modal-header pb-1 border-bottom-0">
                        <h5 class="modal-title" id="product_summary_date">{ProductStatementArr.length && ProductStatementArr[0].EntryDate ? moment(ProductStatementArr[0].EntryDate).format('ddd DD MMM YYYY') : ''}</h5>
                        <div class="d-flex">
                            <div class="align-self-center list-credit-amount">

                                <span class="text-nowrap" id="product_summary_total"><i class="fas fa-rupee-sign"></i>{ProductStatementArr.length ? ProductStatementArr[0].OverallProductTotal : 0}</span>
                            </div>
                            <div class="d-flex header-icon card-header-btn">
                                <button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={() => this.handleCloseProduct("Product")}>
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body pt-0">
                        <div class="table-responsive product-table ">
                            <table class="table table-striped borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Rate</th>
                                        <th scope="col">Sub Total</th>
                                        <th scope="col">Remark</th>
                                        <th scope="col">Tax</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody id="Detailed_product_summary">
                                    {ProductStatementArr.length && !CommonConfig.isEmpty(ProductStatementArr[0].pr_id) ?
                                        ProductStatementArr.map(x => {
                                            return (

                                                <tr>
                                                    <td scope="col">{x.product_name}</td>
                                                    <td scope="col">{x.quantity}</td>
                                                    <td scope="col">{x.rate}</td>
                                                    {/* <td scope="col">{x.subtotal}</td> */}
                                                    <td scope="col"><span>{CommonConfig.isEmpty(x.subtotal) ? '-' : CommonConfig.getIndianFormatAmount(x.subtotal)}</span></td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.remark) ? '-' : x.remark}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.tax_name) ? '-' : x.tax_name}</td>
                                                    <td scope="col">{CommonConfig.isEmpty(x.tax_percen) ? 0 : x.tax_percen} (%)</td>
                                                    <td scope="col">
                                                        <span>{CommonConfig.isEmpty(x.amount) ? '-' : CommonConfig.getIndianFormatAmount(x.amount)}</span>
                                                    </td>
                                                    <td scope="col">
                                                        <span>{CommonConfig.isEmpty(x.Total) ? '-' : CommonConfig.getIndianFormatAmount(x.Total)}</span>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr class="text-center font-weight-bold"><td colspan="9">No record found.</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* </div>
                    </div> */}
                </Modal>
                {/*  Product Statement Modal end  */}

                {/* Credit Debit Modal */}

                <Modal show={showCreditDebitModal} dialogClassName="modal-dialog-centered modal-lg" className="right profile-modal" onHide={() => this.handleCloseProduct("CreditDebitModal")}>
                    {/* <div class="modal-dialog modal-dialog-centered modal-lg">

                        <div class="modal-content"> */}
                    <div class="modal-header pb-1 border-bottom-0">
                        <h5 class="modal-title" id="product_summary_date">Additional Expenses</h5>
                        <div class="d-flex header-icon card-header-btn">
                            <button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={() => this.handleCloseProduct("CreditDebitModal")}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>

                    <div class="modal-body pt-0">
                        <div class="table-responsive product-table ">
                            <table class="table table-striped borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">Amount Type</th>
                                        <th scope="col">New Amount</th>
                                        <th scope="col">New Remark</th>
                                    </tr>
                                </thead>
                                <tbody id="Detailed_product_summary">
                                    {creditDebitList.length ?
                                        creditDebitList.map(x => {
                                            return (

                                                <tr>

                                                    {x.Method === "Addition" ? <td className="align-self-center">
                                                        <button className="credit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0"
                                                        >
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                    </td> :
                                                        <td className="align-self-center">
                                                            <button className="debit-sign text-white p-3 m-0 ml-2 d-flex align-items-center justify-content-center border-0"
                                                            >
                                                                <i className="fa fa-minus"></i>
                                                            </button>
                                                        </td>
                                                    }

                                                    <td scope="col">{x.Amount}</td>
                                                    <td scope="col">{x.Remark}</td>
                                                </tr>
                                            )
                                        }) : <tr class="text-center font-weight-bold"><td colspan="9">No record found.</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* </div>
                    </div> */}
                </Modal>
                <Modal size="lg" backdrop="static" show={showLedgerAddModal} onHide={() => this.handleClose("Ledger")} centered>
                    <div className="modal-content" id="putModalData">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title" id="modalHeader">Add Ledger</h5>
                            <div className="d-flex header-icon card-header-btn">
                                <button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("Ledger")}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body h-auto pt-0">
                            <form onSubmit={(e) => this.submitLedger(e, "Add")}>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="card-element">
                                            <div className="pb-2">
                                                <label htmlFor="username"> Add Party Name </label>
                                            </div>
                                            <input type="text" ref={innerRef => this.innerRef = innerRef} maxlength="50" id="ledger_party_name" value={PartyName} onChange={(e) => this.handleChange(e, "PartyName")} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="card-element">
                                            <div className="pb-2">
                                                <label htmlFor="username"> Contact No</label>
                                            </div>
                                            <input type="text" min="0" step="1" id="ledger_contact_no" maxlength='10' value={ContactNo} onChange={(e) => this.handleChange(e, "ContactNo")} />
                                        </div>
                                    </div>
                                    <div className="col-md-2 mt-3 text-center d-flex justify-content-center">
                                        <button type="submit" className=" btn btn-success Submit-btn align-self-center py-1">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
                <div className='p-d-none' >
                    <div id='credit-statement' className='gatepass-letterhead-container'>
                        <div className='company-letterhead-title'>
                            <p className='company-name'>User Name : {!CommonConfig.isEmpty(creditData[0]?.ledger_username) ? creditData[0]?.ledger_username : '-'}</p>

                        </div>
                        <hr className='letter-divider' />
                        <div className='company-letterhead-body'>
                            <table class="table table-bordered datatable" id="tbl-news">
                                <thead>
                                    <tr>

                                        <th>Date</th>
                                        <th>Credit</th>
                                        <th>Debit</th>
                                        <th>Description</th>
                                    </tr>

                                </thead>
                                <tbody>


                                    {this.state.creditData.length ? this.state.creditData.map((x) => {
                                        return (
                                            <>
                                                <tr>

                                                    <td>{x.created_on ? moment(x.created_on).format('DD MMM YYYY') : ''}</td>
                                                    <td>{x.credit_amount}</td>
                                                    <td>{x.debit_amount}</td>
                                                    <td>{x.description}</td>


                                                </tr>
                                                {
                                                    x.ProductArr.length ?
                                                        <div>
                                                            <div>
                                                                <th>Product name</th>
                                                                <th>Quantity</th>
                                                                <th>Rate</th>
                                                                <th>Remark</th>
                                                            </div>
                                                            {x.ProductArr.length ?

                                                                x.ProductArr.map((x) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{x.product_name}</td>
                                                                            <td>{x.quantity}</td>
                                                                            <td>{x.rate}</td>
                                                                            <td>{x.remark}</td>
                                                                        </tr>
                                                                    )
                                                                }) : <div>No product data found</div>

                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </>

                                        )
                                    }) : <div>No Data Found</div>}

                                    <p>Credit Total : {!CommonConfig.isEmpty(creditData[0]?.CreditCurrentTotal) ? creditData[0]?.CreditCurrentTotal : '0'}</p>
                                    <p>Debit Total : {!CommonConfig.isEmpty(creditData[0]?.DebitCurrentTotal) ? creditData[0]?.DebitCurrentTotal : '0'}</p>
                                    <p>Closing Total : {!CommonConfig.isEmpty(creditData[0]?.DebitClosingBalance) ? creditData[0]?.DebitClosingBalance : '0'}</p>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className='p-d-none' >
                    <div id='dashboard-statement' className='gatepass-letterhead-container'>
                        <div className='company-letterhead-title'>
                            <p className='company-name'>User Name : {!CommonConfig.isEmpty(creditData[0]?.ledger_username) ? creditData[0]?.ledger_username : '-'}</p>

                        </div>
                        <hr className='letter-divider' />
                        <div className='company-letterhead-body'>
                            <table class="table table-bordered datatable" id="tbl-news">
                                <thead>
                                    <tr>

                                        <th>Date</th>
                                        <th>Credit</th>
                                        <th>Debit</th>
                                        <th>Description</th>
                                    </tr>

                                </thead>
                                <tbody>


                                    {this.state.creditData.length ? this.state.creditData.map((x) => {
                                        return (
                                            <>
                                                <tr>

                                                    <td>{x.created_on ? moment(x.created_on).format('DD MMM YYYY') : ''}</td>
                                                    <td>{x.credit_amount}</td>
                                                    <td>{x.debit_amount}</td>
                                                    <td>{x.description}</td>


                                                </tr>
                                                {
                                                    x.ProductArr.length ?
                                                        <div>
                                                            <div>
                                                                <th>Product name</th>
                                                                <th>Quantity</th>
                                                                <th>Rate</th>
                                                                <th>Remark</th>
                                                            </div>
                                                            {x.ProductArr.length ?

                                                                x.ProductArr.map((x) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{x.product_name}</td>
                                                                            <td>{x.quantity}</td>
                                                                            <td>{x.rate}</td>
                                                                            <td>{x.remark}</td>
                                                                        </tr>
                                                                    )
                                                                }) : <div>No product data found</div>

                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </>

                                        )
                                    }) : <div>No Data Found</div>}

                                    <p>Credit Total : {!CommonConfig.isEmpty(creditData[0]?.CreditCurrentTotal) ? creditData[0]?.CreditCurrentTotal : '0'}</p>
                                    <p>Debit Total : {!CommonConfig.isEmpty(creditData[0]?.DebitCurrentTotal) ? creditData[0]?.DebitCurrentTotal : '0'}</p>
                                    <p>Closing Total : {!CommonConfig.isEmpty(creditData[0]?.DebitClosingBalance) ? creditData[0]?.DebitClosingBalance : '0'}</p>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
