import React, { Component } from 'react';
import '../../../assets/css/adminDashboard.scss';
import api from "../../../utils/apiClient";
import moment from 'moment';
import CommonConfig from '../../../utils/constant';
import { Modal } from 'react-bootstrap';


const initialState = {
    UserList: [],
    UserName: '',
    Contact_No: '',
    Start_Date: '',
    end_Date: '',
    Sub_plan: '',
    UserNameSearchString: '',
    ExpiresInSearchString: '',
    menuList: [],
    userAccessModel: false,
    userContactNo: '',

}

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getUsersList();
        this.getProfileDetail();
    }

    getProfileDetail = () => {

        let data = {
            UserID: CommonConfig.loginAdminData().contact_no,
        }
        try {
            api.post("profile/getProfileDetails", data).then(res => {
                if (res.success) {
                    this.setState({
                        UserName: res.data[0].username,
                        Contact_No: res.data[0].contact_no,
                        Start_Date: res.data[0].start_date,
                        end_Date: res.data[0].end_date,
                        Sub_plan: res.data[0].subscription_plan,

                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    getUsersList = () => {

        try {
            api.post("admin/getAdminUserList").then(res => {
                if (res.success) {
                    this.setState({
                        UserList: res.data
                    })
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    handleOpen(ID, type) {
        if (type == "userAccessModel") {
            this.setState({ userAccessModel: true, userContactNo: ID });
            this.getUserAccessByID(ID);
        }
    }
    resetScreen = () => {
        this.setState(initialState);
        this.getUsersList();
        this.getProfileDetail();
    };
    handleClose(type) {
        if (type == "userAccessModel") {
            this.resetScreen();
            this.setState({ userAccessModel: false });
        }
    }
    handleChangeMenu = (e, idx, type) => {
        if (type === "AddAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["AddAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "EditAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["EditAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "ViewAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["ViewAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        } else if (type === "DeleteAccess") {
            let menuList = this.state.menuList;
            menuList[idx]["DeleteAccess"] = e.target.checked ? 1 : 0;
            this.setState({ menuList: menuList });
        }
    };
    getUserAccessByID = async (ID) => {
        let data = {
            UserID: ID
        }
        try {
            await api
                .post('useraccess/getUserAccessByID', data)
                .then(async (res) => {
                    let response = await res;
                    if (response.success) {

                        this.setState({
                            menuList: response.data,
                        });
                    } else {
                        CommonConfig.showMessage(response.data[0].vMessage, response.data[0].vMessageType);
                    }
                })
                .catch((err) => {
                });
        } catch (err) {
        }
    };
    AddUserAccess = (e) => {
        e.preventDefault();
        try {
            let data = {
                SecurityUserID: CommonConfig.loginData().contact_no,
                AccessList: this.state.menuList,
                UserID: this.state.userContactNo
            }
            api.post("useraccess/addUpdateUserAccess", data).then((res) => {
                if (res.success) {
                    this.handleClose("userAccessModel");
                    CommonConfig.showMessage(res.data[0].Message, res.data[0].MessageType);
                }
            }).catch(err => {

            })
        } catch (err) {

        }
    }


    render() {
        const { userAccessModel, menuList, UserList, UserName, Contact_No, Start_Date, end_Date, Sub_plan, UserNameSearchString, ExpiresInSearchString } = this.state;

        let allfilterUser = UserList.filter(
            (alllist) => {
                return ((alllist.username.toLowerCase().indexOf(UserNameSearchString.toLowerCase()) !== -1 || alllist.contact_no.toString().toLowerCase().indexOf(UserNameSearchString.toLowerCase()) !== -1) && alllist.ExpiresIn.toString().toLowerCase().indexOf(ExpiresInSearchString.toLowerCase()) !== -1);
            }
        )
        return (
            <div>
                <div class="admin-menu">
                    <div>
                        <div class="admin-menu-element1">
                            <a href="javascript:;" onClick={() => this.props.history.push('/admin/createuser')}>
                                <div class="menu-detail">Create User </div><i class="fas fa-user"></i>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div class="admin-menu-element2 mt-2">
                            <a href="javascript:;" onClick={() => this.props.history.push('/admin/dashboard')}>
                                <div class="menu-detail">User list </div><i class="fas fa-list-ul"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="admin-container">
                    <div class="card admin-card-body">
                        <div class="px-4 pt-3 pb-2 d-lg-flex justify-content-between align-items-center">
                            <div class="admin-card-body-header ml-lg-0 ml-3">User List</div>
                            <div className="float-right d-lg-flex align-items-center ml-lg-0 ml-2">
                                <div className="admin-card-body-search mt-lg-0 mt-2">
                                    <input
                                        className='form-control'
                                        type="text"
                                        id="username"
                                        placeholder="Search by Name/Mobile No."
                                        onChange={(e) => this.setState({ UserNameSearchString: e.target.value })}
                                        value={UserNameSearchString}
                                    />
                                </div>
                                <div className="admin-card-body-search mt-lg-0 mt-2">
                                    <input
                                        className='form-control'
                                        type="text"
                                        id="expires_in"
                                        onkeypress="handleEnter(event)"
                                        placeholder="Search By Expire In"
                                        onChange={(e) => this.setState({ ExpiresInSearchString: e.target.value })}
                                        value={ExpiresInSearchString}
                                    />
                                </div>
                                {/* <div class="admin-card-body-search"><input type="text" id="" placeholder="Search By URL" value=""></div> */}
                                {/* <div
                                    className="admin-card-header btn-success"
                                    id="click-button"
                                    onclick="filterRecord()"
                                >
                                    {" "}
                                    <i className="fas fa-check" />
                                </div> */}
                                <div
                                    className="admin-card-header btn btn-danger ml-2 border-0"
                                    id="click-button"
                                    onClick={() => {
                                        this.setState(initialState);
                                        this.getUsersList();
                                        this.getProfileDetail();
                                    }}
                                >
                                    {" "}
                                    <i className="fas fa-times" />
                                </div>
                            </div>
                        </div>


                        <div class="px-3 admin-card-area overflow-auto pb-0">
                            <div class="container-fluid">
                                <div class="">
                                    {allfilterUser.map(x => {
                                        return (
                                            <div>
                                                <div class="list-container row">
                                                    <div class="col-12 h-100">
                                                        <div class="row pt-1" style={{ height: "inherit" }}>
                                                            <div class="col-xl-3 col-lg-3 col-md-4 user-info mb-1">
                                                                <a style={{ textDecoration: "none" }} href="javascript:;" onClick={() => this.props.history.push({
                                                                    pathname: "/admin/edituser",
                                                                    state: { UserID: x.contact_no }
                                                                })}>
                                                                    <h5 class="list-user-name text-white"><i class="text-white fas fa-user pr-3"></i>{x.prefix}.
                                                                        {x.username}
                                                                    </h5>
                                                                </a>
                                                                <span class="text-white list-contact"><i class="text-white fas fa-phone-alt pr-3"></i>
                                                                    {x.contact_no}
                                                                </span>

                                                            </div>
                                                            <div class="col-xl-3 col-lg-3 col-md-4">
                                                                <div class="form-group">
                                                                    <label class="font-normal text-white">Remark :</label>
                                                                    <p class="list-user-note mb-0 pl-0">
                                                                        {x.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-1 col-lg-2 col-md-4">
                                                                <div class="form-group list-expire-plan">
                                                                    <label class="text-white">Expires In:</label>
                                                                    <p class="text-white mb-0">

                                                                        {x.ExpiresIn}


                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-2 col-lg-2 col-md-4">
                                                                <div class="text-lg-center">
                                                                    <div>
                                                                        <i class="text-white mb-2 fas fa-hourglass-half"></i>
                                                                    </div>
                                                                    <div>
                                                                        <span class="text-white">{x.subscription_plan}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-3 col-lg-2 col-md-4">
                                                                <div class="row">
                                                                    <div class="col-md-8 mt-lg-0 mt-3">
                                                                        <div class="date-range">
                                                                            <span>From</span>
                                                                            <label><span> {x.start_date ? moment(x.start_date).format('DD MMM YYYY') : ''}</span></label>
                                                                        </div>
                                                                        <div class="date-range ml-2">
                                                                            <span>To </span>
                                                                            <label> <span> {x.end_date ? moment(x.end_date).format('DD MMM YYYY') : ''}</span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-4 align-self-center mt-2'>
                                                                        <div class="statement-icon d-lg-flex justify-content-end" onClick={() => this.handleOpen(x.contact_no, "userAccessModel")}>
                                                                            <i class="fas fa-user-lock text-white"></i>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <hr class="my-0" />
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={userAccessModel} dialogClassName="" className="right profile-modal" onHide={() => this.handleClose("addUserModel")}>
                    <div class="modal-header border-bottom-0">

                        <h5 class="modal-title">
                            User Permission
                        </h5>
                        <div class="d-flex header-icon card-header-btn">
                            <button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={(e) => this.handleClose("userAccessModel")}>
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body user-modal pb-4 py-0">
                        {
                            menuList.length ? (
                                menuList.map((x, idx) => {
                                    return (
                                        <div class="mb-2">
                                            <div className='list-party-name'>
                                                <label> {x.MenuName}</label>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.AddAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "AddAccess")}
                                                        />
                                                        Add
                                                    </label>
                                                </div>
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.EditAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "EditAccess")}
                                                        />
                                                        Edit
                                                    </label>
                                                </div>
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.ViewAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "ViewAccess")}
                                                        />
                                                        View
                                                    </label>
                                                </div>
                                                <div class="col-md-3">
                                                    <label className='align-items-center d-flex'>
                                                        <input type="checkbox" className='mt-0'
                                                            defaultChecked={x.DeleteAccess == 1 ? true : false}
                                                            onChange={(e) => this.handleChangeMenu(e, idx, "DeleteAccess")}
                                                        />
                                                        Delete
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div>
                                    <span>No record found</span>
                                </div>
                            )



                        }
                    </div>
                    <div class="modal-footer" style={{
                        position: 'absolute',
                        bottom: '15px',
                        width: '100%'

                    }}>
                        <div class="text-center">
                            <button type="button" class="btn btn-danger Cancel-btn" id="credit_save_button" onClick={(e) => this.handleClose("userAccessModel")}>Cancel</button>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-success Submit-btn" id="credit_save_button" onClick={(e) => this.AddUserAccess(e)}>Save</button>
                        </div>
                    </div>



                </Modal>
            </div>
        )
    }
}
