import React, { Component } from 'react';
import logo from '../../assets/img/AccountingLogo.png';
import api from "../../utils/apiClient";
import '../../assets/css/style.scss';
import CommonConfig from '../../utils/constant';
import Swal from 'sweetalert2';

const initialState = {
  DBName:'',
  ConfirmPassword:'',
  SetPin:'',
  ConfirmPin:'',

}

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleChange = (e, type) => {
    if (type === "DBName") {
        this.setState({ DBName: e.target.value })
    }
    if (type === "ConfirmPassword") {
        this.setState({ ConfirmPassword: e.target.value })
    }
    if (type === "SetPin") {
        this.setState({ SetPin: e.target.value })
    }
    if (type === "ConfirmPin") {
        this.setState({ ConfirmPin: e.target.value })
    }
}

  render() {
    const {DBName,ConfirmPassword,SetPin,ConfirmPin, } = this.state;
    return (
      <div>
        <section style={{ transform: "translateX(0px)" }}>
          <form>
            <div class="d-flex h-100">
              <div class="w-100 align-self-center">
                <p>Step 1 :</p>
                <div class="form-group">
                  <label for="DBName">Database Name :</label>
                  <input type="text" id="DBName"  value={DBName} onChange={(e) => this.handleChange(e, "DBName")} />
                </div>
                <div class="form-group">
                  <label for="cirmPassWord">Confirm Password :</label>
                  <input type="passWord"  value={ConfirmPassword} onChange={(e) => this.handleChange(e, "ConfirmPassword")} />
                  <div id="confirm_pass_error" class="invalid-feedback"></div>
                </div>
                <div class="row align-items-center">
                  <div class="col-9 d-flex align-items-center">
                    <span>Answer These security questions.</span>
                    <i class="fas fa-chevron-double-right"></i>
                  </div>
                  <div class="col-3">
                    <button type="button" class="loginbtn mb-0" style={{ marginTop: "13px", dataBsToggle: "modal", dataBsTarget: "#QuestionModal" }} id="questionModel">
                      <i class="far fa-question-circle" style={{ fontSize: "24px" }}></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>

        <section id="pin_section" style={{ display: "none", transform: "translateX(100px)" }}>
          <form>
            <div class="d-flex h-100">
              <div class="w-100 align-self-center">
                <p>Step 2 :</p>
                <div class="form-group">
                  <label for="SetPin">Set Pin :</label>
                  <input type="text" id="SetPin" value={SetPin} onChange={(e) => this.handleChange(e, "SetPin")} />
                </div>
                <div class="form-group">
                  <label for="CirmPin">Confirm Pin :</label>
                  <input type="passWord" id="CirmPin" value={ConfirmPin} onChange={(e) => this.handleChange(e, "ConfirmPin")} />
                  <div id="CirmPin_error" class="invalid-feedback"></div>
                </div>
              </div>
            </div>
          </form>
        </section>


        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <div class="button" id="prev">← Previous</div>
          <div class="button" onclick="hideToaster()" id="next">Next →</div>
          <div class="button disabled" onclick="insertDetails()" id="submit">Submit</div>
        </div>


        <div class="modal fade security-modal-box" id="QuestionModal" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Security Questions</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {/* <div class="modal-body">
                <div class="Security-img text-center pb-2"><img src="static/auth/svg/login-questions.svg" alt="" />
                </div>

                <div id="carouselExampleInterval" class="carousel slide h-100" data-interval="false">

                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="security-modal">
                        <div class="security-question" id="ques1">
                          <span class="q-element">Q</span><span class="ue-element">ue.1 </span>
                          Which is your favorite color?
                        </div>
                        <div class="security-answer text-center">
                          <input type="text" id="ans1" onkeypress="closeQuestionModel(event)" />
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="security-modal">
                        <div class="security-question" id="ques2">
                          <span class="q-element">Q</span><span class="ue-element">ue.2 </span>
                          Your birth place name?
                        </div>
                        <div class="security-answer text-center">
                          <input type="text" id="ans2" onkeypress="closeQuestionModel(event)" />
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="security-modal">
                        <div class="security-question" id="ques3">
                          <span class="q-element">Q</span><span class="ue-element">ue.3 </span>
                          Your pet's name?
                        </div>
                        <div class="security-answer text-center">
                          <input type="text" id="ans3" onkeypress="closeQuestionModel(event)" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-block overflow-hidden">
                    <a class="carousel-control-prev" href="#carouselExampleInterval" role="button" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true">
                        <div class="security-back"><i class="fas fa-arrow-left float-left"></i>Back
                        </div>
                      </span>

                    </a>
                    <a class="carousel-control-next" href="#carouselExampleInterval" role="button" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true">
                        <div class="security-next">Next</div>
                      </span>

                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

    )
  }
}



