import Login from '../src/components/Login/Login.js';
import Dashboard from '../src/components/Dashboard/Dashboard.js';
import LockScreen from '../src/components/LockScreen/LockScreen.js';
import UserLogin from '../src/components/Login/UserLogin.js';
import UserAccess from '../src/components/UserAccess/UserAccess.js';
var dashRoutes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/user",
        isAccessRequired: false
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/user",
        isAccessRequired: true
    },
    {
        path: "/useraccess",
        name: "UserAccess",
        component: UserAccess,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/user",
        isAccessRequired: false
    },
    {
        path: "/lockscreen",
        name: "LockScreen",
        component: LockScreen,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/user",
        isAccessRequired: true
    },
    {
        path: "/userlogin",
        name: "UserLogin",
        component: UserLogin,
        parentname: "",
        icon: "",
        invisible: false,
        layout: "/user",
        isAccessRequired: true
    },
]

export default dashRoutes;