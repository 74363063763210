import '../../../assets/css/admin.scss';
import AdminBg from '../../../assets/img/adminbg.jpg';
import React, { Component } from 'react';
import logo from '../../../assets/img/AccountingLogo.png';
import api from "../../../utils/apiClient";
import '../../../assets/css/stylesheet.scss';
import CommonConfig from '../../../utils/constant';
import Swal from 'sweetalert2';


const initialState = {
    UserName: '',
    Password: '',
    alert: null,
    IsFocused : false,
    IsPassFocused : false
}

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        if (CommonConfig.loginAdminData() && localStorage.getItem("isAdminAuthorized")) {
            this.props.history.push('/admin/dashboard')
        }
    }

    handleLogin = (e) => {
        e.preventDefault();

        let data = {
            UserName: this.state.UserName,
            Password: this.state.Password,
            UserType: "role_admin"
        }
        try {
            api.post("authentication/userLogin", data).then(res => {
                if (res.success) {
                    CommonConfig.showMessage(res.message, res.messagetype);
                    localStorage.setItem('loginAdminData', JSON.stringify(res.data));
                    localStorage.setItem('isAdminAuthorized', res.success);
                    setTimeout(() => {
                        this.setState({ alert: null })
                        this.props.history.push('/admin/dashboard');
                    }, 3000);

                    // window.location.href = window.location.origin + '/user/dashboar'
                } else {
                    CommonConfig.showMessage(res.message, res.messagetype);
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    handleChange = (e, type) => {
        if (type === "UserName") {
            this.setState({ UserName: e.target.value,IsFocused : true })
        }
        if (type === "Password") {
            this.setState({ Password: e.target.value,IsPassFocused : true })
        }
    }


    render() {
        const { UserName, Password } = this.state;
        return (
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row full-height">
                        <div className="col-md-8 col-lg-8 px-0 image-side">
                            <div className="login-image">
                                <img src={AdminBg} alt="main-image" />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 px-0 background-img">
                            <div className="d-flex h-100">
                                <div className="login-section">
                                    <div className="login-logo">
                                        <img src={logo} alt="logo-image" />
                                    </div>

                                    <div className="login-header">
                                        Login
                                    </div>
                                    <div className="login-area">
                                        <form onSubmit={(e) => this.handleLogin(e)}>


                                            <div className={this.state.IsFocused ? "inputarea one focus" :"inputarea one"}>
                                                <div className="i">
                                                    <i className="fas fa-user"></i>
                                                </div>
                                                <div className="sectionarea">
                                                    <h5>Username</h5>
                                                    <input type="text" id="username" autoComplete="off" name="username" className="input" value={UserName} onChange={(e) => this.handleChange(e, "UserName")} onFocus={()=>this.setState({IsFocused : true})} onBlur={UserName ? null : ()=>this.setState({IsFocused : false})} id="username" autoFocus="autoFocus" />
                                                </div>
                                            </div>
                                            <div className={this.state.IsPassFocused ? "inputarea pass focus" :"inputarea pass"}>
                                                <div className="i">
                                                    <i className="fas fa-lock"></i>
                                                </div>
                                                <div className="sectionarea">
                                                    <h5>Password</h5>
                                                    <input type="password" id="password" name="password" className="input" value={Password} onChange={(e) => this.handleChange(e, "Password")} id="password" onFocus={()=>this.setState({IsPassFocused : true})} onBlur={Password ? null : ()=>this.setState({IsPassFocused : false})} />
                                                </div>
                                            </div>
                                            <input type="hidden" name="_csrf" value="" />
                                            <div>

                                            </div>
                                            <input type="submit" className="loginbtn" value="Login" />
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                {this.state.alert}
            </div>
        )
    }
}
